import {RootState} from "../../store";

const unscheduledSelectors = {
  getIsFetching: (state: RootState) => state.scheduling.unscheduled.isFetching,
  getIsFetchingState: (state: RootState) =>
    state.scheduling.unscheduled.isFetchingState,
  getUnscheduledLeadList: (state: RootState) =>
    state.scheduling.unscheduled.leadList,
  getUnscheduledLSJobList: (state: RootState) =>
    state.scheduling.unscheduled.loanServicingJobList,
  getLSSelectedJob: (state: RootState) =>
    state.scheduling.unscheduled.loanServicingJobList.selectedJob,
  getAssignLSJobAgentData: (state: RootState) =>
    state.scheduling.unscheduled.lsJobAssignAgent,
  getUnscheduledLeadDetail: (state: RootState) =>
    state.scheduling.unscheduled.leadDetail,
  getUnqualifiedReasons: (state: RootState) =>
    state.scheduling.unscheduled.unqualifiedReason,
  getLoanLockers: (state: RootState) =>
    state.scheduling.unscheduled.loanLockers,
  getPaginationDetails: (state: RootState) =>
    state.scheduling.unscheduled.paginationData,
  getError: (state: RootState) => state.scheduling.unscheduled.error,
  getOpenDrawer: (state: RootState) => state.scheduling.unscheduled.drawerOpen,
  getSendBackReasonList: (state: RootState) =>
    state.scheduling.unscheduled.sendBackReasonList,
  getPickUpLocation: (state: RootState) =>
    state.scheduling.unscheduled.pickupLocation,
  getTimeSlots: (state: RootState) => state.scheduling.unscheduled.timeSlotList,
  getNotes: (state: RootState) => state.scheduling.unscheduled.noteList,
  getGoogleLocations: (state: RootState) =>
    state.scheduling.unscheduled.googleLocations,
  getBlacklistedAddressList: (state: RootState) =>
    state.scheduling.unscheduled.blacklistedAddressList,
  getBypassPacketList: (state: RootState) =>
    state.scheduling.unscheduled.byPassPacketScanningList,
  getUnscheduledLeadCreditDetails: (state: RootState) =>
    state.scheduling.unscheduled.leadCreditDetails,
  getUnscheduledLeadCreditReportStatus: (state: RootState) =>
    state.scheduling.unscheduled.leadCreditReportStatus,
  getModals: (state: RootState) => state.scheduling.unscheduled.modals,
  getLoading: (state: RootState) => state.scheduling.unscheduled.loading,
  getErrors: (state: RootState) => state.scheduling.unscheduled.errors,
  getDeliverySlots: (state: RootState) =>
    state.scheduling.unscheduled.deliverySlots,
  getDeliverySlotChangeableDate: (state: RootState) =>
    state.scheduling.unscheduled.deliverySlotChangeableDate,
  getValidateCustomerDpd : (state: RootState) =>
    state.scheduling.unscheduled.validateCustomerDpdDto,
};
export default unscheduledSelectors;
