import {all, call, getContext, put, takeLatest} from "redux-saga/effects";
import customerProfileActions, {
  customerProfileActionTypes,
} from "./customerProfile.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  CustomerProfileResponse,
  GetCustomerProfileListPayload,
  InitializeCustomerProfilePayload,
  LoanIdPayload,
  UserIdPayload,
  GetInwardReceiptImagePayload,
} from "../../infra/services/api/customerProfile/types";
import {GoldApi} from "../../infra/services/api";
import {snackBarActions} from "../snackbar";
import {loanActions} from "../loan";
import {setCustomerProfileError, setCustomerProfileLoading, setCustomerProfileSuccess} from "./customerProfile.reducer";

const {
  getOverduePaymentDetailsSuccess,
  getOverduePaymentDetailsFailure,
  getRemindersSuccess,
  getRemindersFailure,
  sendReminderSuccess,
  sendReminderFailure,
  getCustomerProfileListSuccess,
  getCustomerProfileListFailure,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
  getInwardReceiptImageFailure,
  getInwardReceiptImageSuccess,
  uploadBatchClosureRefundReportFailure,
  uploadBatchClosureRefundReportSuccess,
} = customerProfileActions;

function* generalErrorFlow(action: PayloadAction<CustomerProfileResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* inwardReceiptErrorFlow(
  action: PayloadAction<CustomerProfileResponse>,
) {
  yield put(
    snackBarActions.open({
      message: "Inward Receipt not available for the given loanId!!",
      variant: "error",
    }),
  );
}

function* getOverduePaymentDetailsFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.getOverduePaymentDetails,
    action.payload,
  );
  if (error) {
    yield put(getOverduePaymentDetailsFailure({response: null, error}));
  } else {
    yield put(getOverduePaymentDetailsSuccess({response, error: null}));
  }
}

function* getRemindersFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.getReminder,
    action.payload,
  );
  if (error) {
    yield put(getRemindersFailure({response: null, error}));
  } else {
    yield put(getRemindersSuccess({response, error: null}));
  }
}

function* sentRemindersFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.sentReminder,
    action.payload,
  );
  if (error) {
    yield put(sendReminderFailure({response: null, error}));
  } else {
    yield put(sendReminderSuccess({response, error: null}));
  }
}

function* initializeCustomerProfileFlow(
  action: PayloadAction<InitializeCustomerProfilePayload>,
) {
  const api: GoldApi = yield getContext("api");
  yield all([
    call(api.loan.getLoanDetails, action.payload),
    call(api.address.getAddressList, action.payload),
  ]);
}

function* getCustomerProfileListFlow(
  action: PayloadAction<GetCustomerProfileListPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.getCustomerProfileList,
    action.payload,
  );
  if (error) {
    yield put(getCustomerProfileListFailure({response: null, error}));
  } else {
    yield put(getCustomerProfileListSuccess({response, error: null}));
  }
}

function* getCustomerDetailsFlow(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.getCustomerDetails,
    action.payload,
  );
  if (error) {
    yield put(getCustomerDetailsFailure({response: null, error}));
  } else {
    yield put(getCustomerDetailsSuccess({response, error: null}));
  }
}

function* getInwardReceiptImageFlow(
  action: PayloadAction<GetInwardReceiptImagePayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.getInwardReceiptImage,
    action.payload,
  );
  if (error) {
    yield put(
      getInwardReceiptImageFailure({response: null, error: error.message}),
    );
  } else {
    yield put(
      getInwardReceiptImageSuccess({response, loanId: action.payload.loanId}),
    );
  }
}

function* enablePartPaymentFlagFlow(action: PayloadAction<LoanIdPayload>) {
  yield put(setCustomerProfileLoading({action: "enablePartPayment"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.enablePartPaymentFlag,
    action.payload,
  );
  if (error) {
    yield put(setCustomerProfileError({action: "enablePartPayment", value: error.message}));
    yield put(
      snackBarActions.open({
        message: "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(setCustomerProfileSuccess({action: "enablePartPayment"}));
    yield put(loanActions.getLoanDetails({loanId: action.payload.loanId}));
    yield put(
      snackBarActions.open({
        message: "In-app part-payment has been allowed.",
        variant: "success",
      }),
    );
  }
}

function* uploadBatchClosureRefundReportFlow(
  action: PayloadAction<FormData>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.customerProfile.uploadBatchClosureRefundReport,
    action.payload,
  );
  if (error) {
    yield put(uploadBatchClosureRefundReportFailure({response: null, error}));
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(uploadBatchClosureRefundReportSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message:
          response.message ||
          "Report uploaded successfully.",
        variant: "success",
      }),
    );
  }
}

export default function* customerProfileSaga() {
  yield takeLatest(
    customerProfileActionTypes.GetOverduePaymentDetails,
    getOverduePaymentDetailsFlow,
  );

  yield takeLatest(
    customerProfileActionTypes.GetOverduePaymentDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(customerProfileActionTypes.GetReminders, getRemindersFlow);

  yield takeLatest(customerProfileActionTypes.SendReminder, sentRemindersFlow);
  yield takeLatest(
    customerProfileActionTypes.SendReminderSuccess,
    function* sendReminderSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Reminder was sent successfully!",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    customerProfileActionTypes.SendReminderFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    customerProfileActionTypes.InitializeCustomerProfile,
    initializeCustomerProfileFlow,
  );

  yield takeLatest(
    customerProfileActionTypes.GetCustomerProfileList,
    getCustomerProfileListFlow,
  );
  yield takeLatest(
    customerProfileActionTypes.GetCustomerProfileListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    customerProfileActionTypes.GetCustomerDetails,
    getCustomerDetailsFlow,
  );
  yield takeLatest(
    customerProfileActionTypes.GetInwardReceiptImage,
    getInwardReceiptImageFlow,
  );
  yield takeLatest(
    customerProfileActionTypes.GetInwardReceiptImageFailure,
    inwardReceiptErrorFlow,
  );
  yield takeLatest(
    customerProfileActionTypes.EnablePartPaymentFlag,
    enablePartPaymentFlagFlow,
  );
  yield takeLatest(
    customerProfileActionTypes.UploadBatchClosureRefundReport,
    uploadBatchClosureRefundReportFlow,
  );
}
