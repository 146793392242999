import React, {useState, useEffect, useCallback} from "react";
import {IconButton, Chip} from "@mui/material";
import {MoreVertRounded} from "@mui/icons-material";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import DrawerList from "../DrawerList";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {RolesEnum} from "app/enums/roles";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useAppSelector, useAppDispatch, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import IGMenu from "app/components/Menu";
import {CRInterface} from "app/models/openCr";
import {City} from "app/models/activeLoans/city";
import IGDrawer from "app/components/IGDrawer";
import CloseCRDialog from "./Dialogs/CloseCRDialog";
import {
  applyFilters,
  setSelectedCR,
  toggleModals,
} from "app/store/activeLoans/activeLoans.reducer";
import {toggleModals as closeCrToggleModal} from "app/store/loanRenewalRebook/loanRenewalRebook.reducer";
import SendBackToSchedulingDialog from "./Dialogs/SendBackToSchedulingDialog";
import ManageJobDialog from "./Dialogs/ManageJobDialog";
import {GridSortDirection} from "@mui/x-data-grid";
import {useIGDialog} from "app/store/hooks/IGDialogHook";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import LoanCancelPopUp from "../ServiceDesk/Services/ManageRenewRebookRequest/CloseAndRenew/LoanRenewalRebook/Components/LoanCancelPopUp";
import {loanRenewRebookSelectors} from "app/store/loanRenewalRebook";
import featureFlagActions from "app/store/featureFlags/featureFlags.actions";
import {featureFlagSelectors} from "app/store/featureFlags";
import {GetActiveLoansCRListPayload} from "app/infra/services/api/activeLoans/types";
import {cmStatusEnum} from "app/enums/gold_loan/activeLoans/filter";
import ConfigApi from "app/infra/services/api/ConfigUtils/configUtils.api";
import SkipCifConfirmationModal from "../DrawerList/MileStoneCard/NewUploadKYC/KYCDetails/Shivalik/Modals/SkipCifModal";

const {
  ADMIN,
  SCHEDULER,
  CREDIT_MANAGER,
  SENIOR_OPERATIONS,
  FOUNDER,
  BUSINESS,
  SCHEDULER_TEAM_LEAD,
  ENGINEERING,
} = RolesEnum;

const ALLOWED_LM_MANAGER = [SCHEDULER, ADMIN, CREDIT_MANAGER];

type LenderApprovalStatus = "Lender Rejected" | "Lender Request Pending" | string;

const crType = "GOLD_LOAN_BT,GOLD_LOAN_FRESH";

const ActiveLoanListing = ({drawer, setDrawer, filtersData}: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const {showConfirmDialog, showSuccessDialog} = useIGDialog();
  const {showError, showSuccess} = useSnackBar();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [isSkipCifModal, setIsSkipCifModal] = useState(false);
  const activeTab = useAppSelector(activeLoansSelectors.getActiveTab);
  const crList = useAppSelector(activeLoansSelectors.getCustomerRequests);
  const selectedCR = useAppSelector(activeLoansSelectors.getSelectedCR);
  const cityList = useAppSelector(activeLoansSelectors.getCitiesList);
  const openModals = useAppSelector(activeLoansSelectors.getOpenModals);
  const user = useAppSelector(authSelectors.getUser);
  const {isLoanCancelModalOpen} = useAppSelector(loanRenewRebookSelectors.getOpenModals);
  const {customerRequests: customerRequestsLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const {pageNo, pageSize} = useAppSelector(activeLoansSelectors.getPagination);
  const isLenderApprovalRequired = useAppSelector(state =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "LOAN_ORIGINATION_LENDER_GATING",
      "IS_LENDER_APPROVAL_REQUIRED_FOR_LOAN_BOOKING",
    ),
  );

  const getCustomerRequestsList = useCallback(() => {
    if (activeTab === "DOC_VERIFICATION") return;

    const payload: GetActiveLoansCRListPayload = {
      pageNo: pageNo,
      pageSize: pageSize,
      crId: filtersData.selectedCrId || "",
      crType: crType,
      fetchActive: filtersData.fetchActive,
      sortCriteria: filtersData.sortCriteria || "",
      sortOrder: filtersData.sortOrder || "",
      isCmQueue: activeTab === "CREDIT_QUEUE",
    };

    if (!Boolean(filtersData.selectedCrId)) {
      payload.startTime = filtersData.fromDate;
      payload.endTime = filtersData.toDate;
    }

    if (filtersData.cmStatus !== cmStatusEnum.ALL_CM_STATUS) {
      payload.cmStatus = filtersData.cmStatus;
    }

    if (filtersData.selectedCityId !== 0) {
      payload.cityId = filtersData.selectedCityId;
    }

    dispatch(activeLoansActions.getCrList(payload));
  }, [pageSize, pageNo, filtersData, activeTab, drawer, dispatch]);

  const getCityNameFromId = (cityId: number, cityList: City[]) => {
    const cityObject = cityList.find(item => item.id === cityId);
    if (cityObject) {
      return cityObject.name;
    }
    return "Not Found";
  };

  const removeCreditManager = (crId: number) => {
    dispatch(activeLoansActions.removeCreditManager(crId));
  };

  const assignCRToMe = (crId: number) => {
    if (user && "id" in user) {
      dispatch(
        activeLoansActions.assignCrToMe({
          crId,
          userId: Number(user.id),
        }),
      );
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(
      activeLoansActions.setActiveLoansPagination({
        pageSize,
      }),
    );
    handlePageChange(1);
  };

  const handlePageChange = (pageNo: number) => {
    dispatch(
      activeLoansActions.setActiveLoansPagination({
        pageNo,
      }),
    );
  };

  const updateSkipGeofencing = useCallback(
    async (value: boolean, crId: number) => {
      const {error} = await ConfigApi.updateCrFeatureFlag({
        crId,
        flagName: "SKIP_CONTACT_CUSTOMER_GEOFENCING",
        value,
        remark: "",
      });
      if (!error) {
        showSuccess("Successfully Updated!");
      } else {
        showError(error.message);
      }
    },
    [showError, showSuccess],
  );

  const handleViewRequest = (crId: number) => {
    navigate(`/gold-loan/utilities/config-utils?activeTab=CR_FEATURE_FLAG&crId=${crId}`);
  };

  const handleGenerateDuplicateCR = (crId: number) => {
    showConfirmDialog({
      title: `Generate Duplicate CR #${crId}`,
      content: "Are you sure you want to copy details and create a new cr for this loan?",
      onConfirm: async () => {
        const {response, error} = await activeLoansApi.generateDuplicateCr({
          crId,
        });

        if (!error) {
          const newCr = response.crId;
          showSuccessDialog({
            message: `New customer request ${newCr} created successfully!`,
          });
        } else {
          showError(error.message || "Something went wrong!");
        }
      },
    });
  };

  const sortEnum: Record<string, string> = {
    name: "USER_NAME",
    type: "JOB_TYPE",
    dealValue: "DEAL_VALUE",
    milestoneName: "MILESTONE",
    id: "CUSTOMER_REQUEST_ID",
    actionRequired: "ACTION_REQUIRED",
  };

  const sortOrderEnum: Record<keyof GridSortDirection, string> = {
    asc: "ASCENDING",
    desc: "DESCENDING",
  };

  const tableCols: IGTableColsInterface<CRInterface> = [
    {
      field: "id",
      headerName: "REQ. ID",
      headerClassName: "active-loans-header",
    },
    {
      field: "name",
      headerName: "LOAN BOOKED FOR",
      headerClassName: "active-loans-header",
    },
    {
      field: "loanType",
      headerName: "LOAN TYPE",
      headerClassName: "active-loans-header",
    },
    {
      field: "businessFieldAgentResponseDtoList",
      headerName: "LOAN MANAGERS",
      headerClassName: "active-loans-header",
      sortable: false,
      renderCell: ({value}) => {
        const names = value.map((e: any) => e.name);
        return <>{names.join(",")}</>;
      },
    },
    {
      field: "dealValue",
      headerName: "LOAN AMOUNT",
      headerClassName: "active-loans-header",
      renderCell: ({value}) => <>{numberWithCurrencyAndComma(value)}</>,
    },
    {
      field: "cityId",
      headerName: "CITY",
      sortable: false,
      headerClassName: "active-loans-header",
      renderCell: ({value}) => <>{getCityNameFromId(value, cityList)}</>,
    },

    {
      field: `${activeTab === "CREDIT_QUEUE" ? "actionRequired" : "milestoneName"}`,
      headerName: `${activeTab === "CREDIT_QUEUE" ? "ACTION REQUIRED" : "CURRENT STAGE"}`,
      headerClassName: "active-loans-header",
      flex: 1.5,
      renderCell: ({value}) => {
        const color: Record<LenderApprovalStatus, "error" | "warning" | "default"> = {
          "Lender Rejected": "error",
          "Lender Request Pending": "warning",
        };
        return <Chip label={value} color={color[value] || "default"} />;
      },
    },
    {
      field: "actions",
      headerName: "ACTION",
      sortable: false,
      headerClassName: "active-loans-header",
      renderCell: ({row}) => {
        const {id, creditManagerId, loanType} = row;
        return (
          <IGMenu
            actionButtonProps={{
              id: `${id}_activeLoans_actions`,
              label: "more",
              onClick: () => {
                setSelectedRow(id);
                dispatch(setSelectedCR(row));
              },
              elem: props => (
                <IconButton {...props} color="primary">
                  <MoreVertRounded />
                </IconButton>
              ),
            }}
            actionMenuProps={{
              id: `${id}_activeLoans_menu`,
              items: [
                {
                  id: "assign_to_me",
                  label: "Assign To Me",
                  renderCondition: !creditManagerId,
                  onClick: () => assignCRToMe(id),
                },
                {
                  id: "un_assign_cm",
                  label: "Un Assign Credit Manager",
                  renderCondition: !!creditManagerId,
                  onClick: () => removeCreditManager(id),
                },
                {
                  id: "close_cr",
                  label: "Close Customer Request",
                  renderCondition: !!creditManagerId,
                  onClick: () => {
                    setSelectedRow(id);
                    dispatch(
                      closeCrToggleModal({
                        type: "isLoanCancelModalOpen",
                        value: true,
                      }),
                    );
                  },
                },

                {
                  id: "manage_job",
                  label: "Manage Job/Agents",
                  renderCondition:
                    (user &&
                      "userRoleList" in user &&
                      user.userRoleList.some(role => ALLOWED_LM_MANAGER.includes(role))) ||
                    false,
                  onClick: () => {
                    setSelectedRow(id);
                    dispatch(
                      toggleModals({
                        type: "manageJob",
                        value: true,
                      }),
                    );
                  },
                },
                {
                  id: "send_back_to_scheduling",
                  label: "Send Back To Scheduling",
                  renderCondition: true,
                  onClick: () => {
                    setSelectedRow(id);
                    dispatch(
                      toggleModals({
                        type: "sendBackToSchedulingDialog",
                        value: true,
                      }),
                    );
                  },
                },
                {
                  id: "skip_office_review",
                  label: "Skip Office Review",
                  renderCondition: true,
                  onClick: () => {
                    dispatch(
                      activeLoansActions.skipOfficeReview({
                        crId: id,
                        isSkipped: true,
                      }),
                    );
                  },
                },

                {
                  id: "activate_loan-maker",
                  label: "Generate Manual offer",
                  renderCondition: true,
                  onClick: () => {
                    dispatch(
                      activeLoansActions.activateLoanMaker({
                        crId: id,
                      }),
                    );
                  },
                },
                {
                  id: "generate_ducplicate_cr",
                  label: "Generate Duplicate CR",
                  renderCondition: user?.userRoleList.some(
                    role =>
                      [RolesEnum.ADMIN, RolesEnum.CREDIT_MANAGER, RolesEnum.FOUNDER].includes(
                        role,
                      ) && ["FRESH", "TAKEOVER"].includes(loanType),
                  ),
                  onClick: () => {
                    handleGenerateDuplicateCR(id);
                  },
                },

                {
                  id: "send_approval_mail",
                  label: "Send Approval Mail to Shivalik",
                  renderCondition: !isLenderApprovalRequired,
                  onClick: () => {
                    dispatch(
                      activeLoansActions.sendApprovalMailToLender({
                        crId: id,
                      }),
                    );
                  },
                },
                {
                  id: "skip_cif-bt",
                  label: "Skip Cif For BT",
                  renderCondition: true,
                  onClick: () => {
                    setIsSkipCifModal(true);
                  },
                },
                {
                  id: "config-dashboard",
                  label: "Config dashboard",
                  renderCondition: user?.userRoleList?.some(role =>
                    [ADMIN, CREDIT_MANAGER, ENGINEERING].includes(role),
                  ),
                  onClick: () => {
                    handleViewRequest(id);
                  },
                },
                {
                  id: "skip_geofencing_check",
                  label: "Skip Geofencing Check",
                  renderCondition: true,
                  onClick: () => {
                    updateSkipGeofencing(true, id);
                  },
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const onRowClickHandler = (row: CRInterface) => {
    setSelectedRow(row.id);
    dispatch(setSelectedCR(row));
    setDrawer(true);
  };

  const showActions =
    user?.userRoleList?.some(role =>
      [
        ADMIN,
        CREDIT_MANAGER,
        SENIOR_OPERATIONS,
        FOUNDER,
        BUSINESS,
        SCHEDULER,
        SCHEDULER_TEAM_LEAD,
      ].includes(role),
    ) || false;

  useEffect(() => {
    if (params.activeCrIdGoldLoan) {
      dispatch(
        applyFilters({
          ...filtersData,
          selectedCrId: params.activeCrIdGoldLoan,
        }),
      );
    }
  }, [params, dispatch, filtersData]);

  useEffect(() => {
    dispatch(
      featureFlagActions.getFeatureFlagList([
        {
          moduleEnum: "LOAN_ORIGINATION_LENDER_GATING",
          moduleFeatureEnum: "IS_LENDER_APPROVAL_REQUIRED_FOR_LOAN_BOOKING",
        },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    getCustomerRequestsList();
  }, [getCustomerRequestsList]);

  return (
    <>
      <IGTable<CRInterface>
        tableCols={tableCols}
        tableData={crList}
        getRowId={row => row.id}
        selectedRowId={selectedRow}
        loading={customerRequestsLoading}
        onRowClick={onRowClickHandler}
        pagination={true}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        page={pageNo}
        pageSize={pageSize}
        sortModel={[
          {
            field:
              Object.keys(sortEnum).find(key => sortEnum[key] === filtersData.sortCriteria) || "",
            sort:
              (Object.keys(sortOrderEnum).find(
                key => sortOrderEnum[key as keyof GridSortDirection] === filtersData.sortOrder,
              ) as keyof GridSortDirection) || null,
          },
        ]}
        onSortModelChange={model => {
          if (model.length > 0) {
            dispatch(
              applyFilters({
                ...filtersData,
                sortCriteria: sortEnum[model[0].field],
                sortOrder: sortOrderEnum[model[0].sort as keyof GridSortDirection],
              }),
            );
            return;
          }
          if (filtersData.sortOrder === "DESCENDING") {
            dispatch(
              applyFilters({
                ...filtersData,
                sortOrder: "ASCENDING",
              }),
            );
            return;
          }
        }}
        // passing undefined because we are not getting totalCount from api
        // dynamic value for pagination will be calculated inside IGTAble
        totalRows={undefined}
        disabledNext={crList.length < pageSize}
        disabledPrev={pageNo <= 1}
        paginationType="single"
        columnVisibilityModel={{
          actions: showActions,
        }}
        sx={{
          "& .active-loans-header": {
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#000000",
          },
          "& .active-loans-header .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .active-loans-header .MuiSvgIcon-root": {
            fill: "white",
          },
        }}
      />

      <IGDrawer
        open={drawer && selectedCR}
        onClose={() => {
          setDrawer(false);
        }}
      >
        <DrawerList selectedPayload={selectedCR} />
      </IGDrawer>

      {isLoanCancelModalOpen && <LoanCancelPopUp crId={selectedRow} />}

      <CloseCRDialog open={openModals.closeCRDialog} crId={selectedRow} />

      <ManageJobDialog open={openModals.manageJob} crId={selectedRow} />

      <SendBackToSchedulingDialog open={openModals.sendBackToSchedulingDialog} crId={selectedRow} />
      {isSkipCifModal && (
        <SkipCifConfirmationModal
          isSkipCifConfirmationModalOpen={isSkipCifModal}
          setIsSkipCifConfirmationModalOpen={setIsSkipCifModal}
          crId={selectedRow}
          lenderName="SHIVALIK"
          isBtCifSkip={true}
        />
      )}
    </>
  );
};

export default ActiveLoanListing;
