import React from "react";
import {Box, Chip, Grid, Typography} from "@mui/material";
import GuarantorDocView from "../GuarantorDocView";
import {useAppSelector} from "app/store/hooks";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";
import tippy from "tippy.js";
import {InfoRounded} from "@mui/icons-material";

const GuarantorSupplementDocuments = () => {
  const guarantorDocuments = useAppSelector(
    personalDetailsSelectors.getKycDocuments,
  );

  return (
    <Box
      bgcolor="#ffff"
      borderRadius="4px"
      padding="16px 16px 16px 24px"
      border="1px solid #0000001F"
    >
      <Grid container spacing={1}>
        {guarantorDocuments
          .filter(
            (i) =>
              i.documentType !== "GUARANTOR_AADHAAR" &&
              i.documentType !== "GUARANTOR_PAN",
          )
          .map((doc) => (
            <Grid item xs={6}>
              <Typography
                fontSize="14px"
                fontFamily="roboto"
                fontWeight={500}
                sx={{
                  textTransform:"capitalize",
                }}
              >
                {doc?.documentType.replaceAll("_", " ").toLowerCase()}
              </Typography>
              {doc.provisionalDocStatus && (
                <Chip
                  label={doc.provisionalDocStatus || "Pre-Verified"}
                  color="success"
                  size="small"
                  sx={{
                    backgroundColor: "#c8e6c9",
                    color: "#307e34",
                    fontWeight: "bold",
                    my: "4px",
                  }}
                />
              )}
              {doc?.isBlackListed && (
                <Chip
                  label={"Blacklisted"}
                  color="success"
                  size="small"
                  sx={{
                    background: "#f2c1c1",
                    color: "#c62828",
                    fontWeight: "bold",
                  }}
                  icon={<InfoRounded />}
                  onMouseEnter={event => {
                    tippy(event.currentTarget, {
                      content: doc?.blacklistReason,
                      placement: "top",
                    });
                  }}
                  onMouseLeave={event => {
                    tippy(event.currentTarget).destroy();
                  }}
                />
              )}
              <GuarantorDocView guarantorDocument={doc} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default GuarantorSupplementDocuments;
