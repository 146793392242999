import {ConfigProduct} from "app/enums/serviceDesk";
import {RootState} from "app/store/store";
import {selectAllAgents, selectAllServiceRequests, selectServiceRequestById} from "../reducer";

//Services Selectors
const servicesSelectors = {
  filterServiceRequestsByMobile: (state: RootState, mobile?: number) =>
    mobile
      ? selectAllServiceRequests(state).filter(
          (item) => item?.mobile === mobile,
        )
      : [],
  getSearchFilters: (state: RootState) =>
    state.serviceDesk.services.searchFilters,
  getFilters: (state: RootState) => state.serviceDesk.services.filters,
  getServiceConfig: (state: RootState, product: ConfigProduct) =>
    state.serviceDesk.config.SERVICE[product],
  getComplaintConfig: (state: RootState, product: ConfigProduct) =>
    state.serviceDesk.config.COMPLAINT[product],
  getAgentList: (state: RootState) => selectAllAgents(state),
  getOpenModals: (state: RootState) => state.serviceDesk.services.openModals,
  getListReload: (state: RootState) => state.serviceDesk.services.listReload,
  getLoanDetails: (state: RootState) => state.serviceDesk.services.loanDetail,
  getLoading: (state: RootState) => state.serviceDesk.services.loading,
  getRowAction: (state: RootState) => state.serviceDesk.services.rowAction,
  getServicesPagination: (state: RootState) => state.serviceDesk.services.servicesPagination,
  getComplaintsPagination: (state: RootState) => state.serviceDesk.services.complaintsPagination,
  getLoansByMobileNumber: (state: RootState) =>
    state.serviceDesk.services.loansByMobileNumber,
  getCreateRequestResponse: (state: RootState) =>
    state.serviceDesk.services.createRequestResponse,
  getCustomerAvailability: (state: RootState) =>
    state.serviceDesk.services.customerAvailability,
  getCurrentServiceType: (state: RootState) =>
    state.serviceDesk.services.filters.serviceType,
  getBypassPacketList: (state: RootState) =>
    state.serviceDesk.services.byPassPacketScanningList,
  getPaymentDetails: (state: RootState) =>
    state.serviceDesk.services.paymentDetail,
  getErrors: (state: RootState) => state.serviceDesk.services.error,
  getAllLoans: (state: RootState) => state.serviceDesk.services.userAllLoans,
  getForeclosureCharge: (state: RootState) =>
    state.serviceDesk.services.foreclosureCharge,
  getReload: (state: RootState) => state.serviceDesk.services.reload,
  getCancellationReasonList: (state: RootState) =>
    state.serviceDesk.services.cancellationReasonList,
  getSoaVerification: (state: RootState) =>
    state.serviceDesk.services.soaVerification,
  getIsRateChangeRequestCreationAllowed: (state: RootState) =>
    state.serviceDesk.services.isRateChangeRequestCreationAllowed,
  getTatDetails: (state: RootState, id: number) =>
    selectServiceRequestById(state, id)?.statusDetailResponseDtoList || [],
  getSuccess: (state: RootState) => state.serviceDesk.services.success,
  getEventHistory: (state: RootState, id: number) =>
    state.serviceDesk.services.eventHistory[id],
  getEventMasterList: (state: RootState) =>
    state.serviceDesk.services.eventMasterList,
  getLatestEvent: (state: RootState) => state.serviceDesk.services.latestEvent,
  getLenderClosureAmount: (state: RootState) =>
    state.serviceDesk.services.lenderClosureAmount,
};

export default servicesSelectors;
