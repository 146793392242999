import {IGDialog, IGButton, IGTextField, IGSelect} from "app/components";
import {Box} from "@mui/material";
import React, {useCallback, useState} from "react";
import skipCifEnable from "api/activeLoan/skipCifEnable";
import {useSnackBar} from "app/store/hooks";
import {SkipCifEnableOptions} from "app/enums/gold_loan/activeLoans";

interface SkipCifConfirmationModalProps {
  isSkipCifConfirmationModalOpen: boolean;
  crId: number;
  lenderName: string;
  setIsSkipCifConfirmationModalOpen: (args: boolean) => void;
  isBtCifSkip: boolean;
}
const SkipCifConfirmationModal = ({
  isSkipCifConfirmationModalOpen,
  setIsSkipCifConfirmationModalOpen,
  crId,
  lenderName,
  isBtCifSkip = false,
}: SkipCifConfirmationModalProps) => {
  const {showError, showSuccess} = useSnackBar();
  const [skipCifRemark, setSkipCifRemark] = useState("");
  const [selectedRemarkEnum, setSelectedRemarkEnum] = useState("");
  const [isCifSkipLoading, setIsCifSkipLoading] = useState(false);

  const onClickConfirmHandler = useCallback(async () => {
    if (!skipCifRemark || !selectedRemarkEnum) {
      showError("Please Provide Required Fields!");
      return;
    }
    setIsCifSkipLoading(true);
    const {error, response} = await skipCifEnable({
      crId: crId,
      lenderName: lenderName,
      remark: skipCifRemark,
      remarkEnum: selectedRemarkEnum,
      customerType: isBtCifSkip ?  "LOAN_RELEASE" : "",
    });
    if (!error) {
      showSuccess(response.message || "successfully updated!");
      setIsCifSkipLoading(false);
      setIsSkipCifConfirmationModalOpen(false);
    } else {
      setIsCifSkipLoading(false);
      showError(error.message);
    }
  }, [
    crId,
    lenderName,
    selectedRemarkEnum,
    setIsSkipCifConfirmationModalOpen,
    showError,
    showSuccess,
    skipCifRemark,
    isBtCifSkip,
  ]);

  return (
    <IGDialog
      open={isSkipCifConfirmationModalOpen}
      onClose={() => setIsSkipCifConfirmationModalOpen(false)}
      title="Are you sure for making Cif skip enable?"
      hideFooter
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <IGTextField
          type="text"
          fullWidth={false}
          name="skipCifRemark"
          value={skipCifRemark}
          onChange={(ev) => setSkipCifRemark(ev.target.value)}
          label="Please Provide remark"
        />
        <IGSelect
          fullWidth={false}
          label="Please select any remark Enum"
          name="selectedRemarkEnum"
          onChange={(ev) => setSelectedRemarkEnum(ev.target.value)}
          value={selectedRemarkEnum}
          options={SkipCifEnableOptions.map((item) => ({
            text: item.replaceAll("_", " "),
            value: item,
          }))}
        />
      </Box>
      <Box mt={2} display="flex" gap={1} justifyContent="flex-end">
        <IGButton
          color="inherit"
          onClick={() => setIsSkipCifConfirmationModalOpen(false)}
        >
          Cancel
        </IGButton>
        <IGButton loading={isCifSkipLoading} onClick={onClickConfirmHandler}>
          Confirm
        </IGButton>
      </Box>
    </IGDialog>
  );
};

export default SkipCifConfirmationModal;
