import {createSlice, current} from "@reduxjs/toolkit";
import ROUTES from "app/constants/routes";
import {Section} from "app/models/feature";
import {Product} from "app/models/product";
import {UserInterface} from "../../models/user";
import {layoutActions} from "../layout";
import {actions} from "./actions";
import {getMenu} from "app/models/product";

type Server =
  | "fs1"
  | "fs2"
  | "fs3"
  | "fs4"
  | "fs5"
  | "fs6"
  | "fs7"
  | "fs8"
  | "fs9"
  | "fs10"
  | "dev"
  | "stg"
  | "api"
  | "fs1-java"
  | "localhost"
  | "fs2-java"
  | "dev-java"
  | "fs5-java"
  | "dev-java"
  | "fs9-java"
  | "fs3-java"
  | "fs4-java"
  | "fs6-java";

export const availableServers: Server[] = [
  "fs1",
  "fs2",
  "fs3",
  "fs4",
  "fs5",
  "fs6",
  "fs7",
  "fs8",
  "fs9",
  "fs10",
  "dev",
  "stg",
  "api",
  "fs1-java",
  "dev-java",
  "fs2-java",
  "localhost",
  "fs5-java",
  "fs9-java",
  "fs6-java",
  "fs4-java",
  "fs3-java",
];

// >>>>>>> Auth State Types <<<<<<<<<
export interface AuthState {
  appSignature?: string | null;
  authToken?: string | null;
  refreshToken?: string | null;
  user?: UserInterface | null;
  isAuthenticated?: boolean; // These two states are managed in the auth reducer
  loading: Record<string, boolean>;
  isGoogleAuth?: boolean; // so no need to manipulate it in the Api/Middleware .
  error: Record<string, string | null>;
  server?: Server;
  otpId?: number | null;
}

// >>>>>>> Auth Initial State <<<<<<<<<
export const initialState: AuthState = {
  appSignature: null,
  authToken: null,
  refreshToken: null,
  isAuthenticated: false,
  user: null,
  loading: {
    sendOtp: false,
    verifyOtp: false,
    logout: false,
    renewAccessToken: false,
    changePassword: false,
    postGoogleLogin: false,
    layout: false,
  },
  error: {
    sendOtp: null,
    verifyOtp: null,
    logout: null,
    renewAccessToken: null,
    changePassword: null,
    postGoogleLogin: null,
  },
  isGoogleAuth: false,
  otpId: null,
  server:
    process.env.REACT_APP_ENV === "development"
      ? (localStorage.getItem("server") as Server) || "dev"
      : "api",
};

// >>>>>>> Auth Slice <<<<<<<<<<
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // A reducer to reset Auth State
    resetAuthState: state => {
      state.appSignature = null;
      state.authToken = null;
      state.refreshToken = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isGoogleAuth = false;
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.server = "dev";
      state.otpId = null;
    },

    // A reducer to set Auth State
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },

    setAppSignature: (state, action) => {
      state.appSignature = action.payload;
    },
    setLoading: (state, action) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    serverChanged(state, action) {
      state.server = action.payload;
      localStorage.setItem("server", action.payload);
    },
  },
  extraReducers: {
    /**
     * Below reducers are written as extraReducers to prevent
     * new redux-toolkit complexities from being introduced
     * in our predefined buisness logics of Authentication
     */
    [actions.login.type]: state => {
      state.loading.login = true;
    },
    [actions.loginSuccess.type]: (state, action) => {
      state.loading.login = false;
      state.loading.postGoogleLogin = false;
      state.isGoogleAuth = false;
      state.isAuthenticated = true;
      state.authToken = action.payload.authToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
    },
    [actions.loginFailure.type]: (state, action) => {
      state.error = action.payload.error;
      state.loading.login = false;
      state.isGoogleAuth = false;
      state.isAuthenticated = false;
    },
    [actions.logout.type]: state => {
      state.loading.logout = true;
    },
    [actions.logoutSuccess.type]: state => {
      state.user = null;
      state.authToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.loading.logout = false;
    },
    [actions.logoutFailure.type]: (state, action) => {
      state.loading.logout = false;
      state.error.logout = action.payload.error;
    },
    [actions.renewAccessToken.type]: state => {
      state.loading.renewAccessToken = true;
    },
    [actions.renewAccessTokenSuccess.type]: (state, action) => {
      state.loading.renewAccessToken = false;
      state.authToken = action.payload.authToken;
    },
    [actions.renewAccessTokenFailure.type]: (state, action) => {
      state.loading.renewAccessToken = false;
      state.error.renewAccessToken = action.payload.error;
      state.isAuthenticated = false;
    },
    [actions.changePassword.type]: state => {
      state.loading.changePassword = true;
    },
    [actions.changePasswordSuccess.type]: (state, action) => {
      state.loading.changePassword = false;
      state.isAuthenticated = true;
      state.authToken = action.payload.authToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
    },
    [actions.changePasswordFailure.type]: state => {
      state.loading.changePassword = false;
    },

    [actions.postGoogleLogin.type]: state => {
      state.loading.postGoogleLogin = true;
      state.isGoogleAuth = true;
    },

    [actions.sendOtp.type]: state => {
      state.loading.sendOtp = true;
    },

    [actions.sendOtpSuccess.type]: (state, action) => {
      state.loading.sendOtp = false;
      state.otpId = action.payload.otpId;
    },

    [actions.sendOtpFailure.type]: (state, action) => {
      state.loading.sendOtp = false;
      state.error.sendOtp = action.payload.error;
    },

    [actions.verifyOtp.type]: state => {
      state.loading.verifyOtp = true;
      state.error.verifyOtp = null;
    },

    [actions.verifyOtpSuccess.type]: (state, action) => {
      state.loading.verifyOtp = false;
      state.error.verifyOtp = null;
      state.isGoogleAuth = false;
      state.isAuthenticated = true;
      state.authToken = action.payload.authToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
      state.otpId = null;
    },

    [actions.verifyOtpFailure.type]: (state, action) => {
      state.loading.verifyOtp = false;
      state.error.verifyOtp = action.payload.error;
      state.isGoogleAuth = false;
      state.isAuthenticated = false;
    },
    [layoutActions.getLenderMenuSuccess.type]: (state, action) => {
      state.loading.layout = true;
      // Add the menu in user product - Lender Portal
      const currentUser = current(state.user);
      const lenderPortal: Product = {
        ...(currentUser?.products?.find(product => product?.name === "LENDER_PORTAL") || {
          id: 4,
          name: "LENDER_PORTAL",
          displayName: "Lender Portal",
          path: ROUTES.LENDER_PORTAL,
          availableFeatures: [],
          getMenu,
        }),
      };
      const lenderPortalIndex = currentUser?.products?.findIndex(
        product => product?.name === "LENDER_PORTAL",
      );

      if (currentUser) {
        const sections: Section[] = action.payload?.response;
        const dynamicSubMenuFeatures = lenderPortal.availableFeatures.filter(
          feature => feature.dynamicSubMenu,
        );
        lenderPortal.availableFeatures = [
          ...dynamicSubMenuFeatures,
          ...sections.map(section => {
            return {
              name: section.name,
              path: section.enum.replaceAll("_", "-").toLowerCase(),
              enum: section.enum,
              subFeatures: section.subSectionList?.map((subSection: any) => {
                return {
                  name: subSection.name,
                  path: subSection.enum.replaceAll("_", "-").toLowerCase(),
                  enum: subSection.enum,
                  whitelistedRoles: [...(currentUser?.userRoleList || [])],
                };
              }),
              whitelistedRoles: [...(currentUser?.userRoleList || [])],
            };
          }),
        ];
        lenderPortal.defaultFeature =
          lenderPortal.availableFeatures.find(feature => feature.path === "loan-management") ||
          lenderPortal.availableFeatures[0];
        if (state.user && state.user.products && lenderPortalIndex !== undefined) {
          state.user.products[lenderPortalIndex as number] = lenderPortal;
        }
      }
      state.loading.layout = false;
    },
  },
});

const {
  resetAuthState,
  setAuthToken,
  setAppSignature,
  setLoading,
  serverChanged,
} = authSlice.actions;

export default authSlice.reducer;
export {resetAuthState, setAuthToken, setAppSignature, setLoading, serverChanged};
