import {RootState} from "app/store/store";
import {RenewRebookActions, selectAllOffers} from "./reducer";

//Renew Rebook Selectors
const renewRebookSelectors = {
  getListReload: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.listReload,
  getActiveStep: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.customerReqFormActiveStep,
  getCompletedSteps: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.completedSteps,
  getLoading: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.loading,
  getOurOffer: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.ourOfferDetails,
  getVersionHistory: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.versionHistory,
  getNetPayable: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.netPayable,
  getOrnamentList: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.ornamentList,
  getCustomerFinancials: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.customerFinancials,
  getCustomerRequirements: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.customerRequirements,
  getRepaymentHistory: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.repaymentHistory,
  getCMOfferDetails: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.cmOfferDetails,
  getTotalGoldValuation: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.totalGoldValuation,
  getRenewRebookConfig: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.renewalConfig,
  getCMConfig: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.cmConfig,
  getPaymentDetails: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.paymentDetail,
  getLoanRenewalClosureDetails: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.loanRenewalClosureDetail,
  getErrors: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.error,
  getLoanRenewalMappedPayments: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.loanRenewalMappedPayments,
  getUserAcceptedOffer: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.userAcceptedOffer,
  getGoldRate: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.goldRate,
  getCMAdjustmentRenewalDetails: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.CMAdjustmentRenewalDetail,
  getOpenModals: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.openModals,
  getForceSubmitCmForm: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.forceSubmitCmForm,
  getSuccess: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.success,
  isOfferEngineApplicable: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.isOfferEngineApplicable,
  isOfferListVisibleToConsumer: (state: RootState) =>
    state.serviceDesk.services.renewRebookState.isOfferListVisibleToConsumer,
  isOfferSelectedByConsumer : (state: RootState) =>
    state.serviceDesk.services.renewRebookState.isOfferSelected,
  getOfferList: (state: RootState) => state.serviceDesk.services.renewRebookState.offerList,
  selectRenewRebookAction: (state: RootState, action: RenewRebookActions) =>
    state.serviceDesk.services.renewRebookState.actions[action],
  selectOffersByRequestId: (state: RootState, requestId: string) =>
    selectAllOffers(state).filter(
      (offer) => String(offer.requestId) === String(requestId),
    ),
};

export default renewRebookSelectors;
