import {createSlice} from "@reduxjs/toolkit";
import {layoutActions} from "./actions";
import {DynamicRoutesInterface, GoldApiError} from "../../typings/api/goldApi.types";

interface FeatureFlag {
  id: number
  moduleEnum: string
  featureName: string
  featureNameEnum: string
  isFeatureActive: boolean
}


export interface LayoutState {
  isFetching: boolean
  error: GoldApiError | null
  dynamicRoutes: DynamicRoutesInterface | null,
  sections: any
  featureFlags: FeatureFlag[]
}

const initialState: LayoutState = {
  isFetching: false,
  error: null,
  dynamicRoutes: null,
  sections: null,
  featureFlags: [],
};

export const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setAllSubMenus: (state, action) => {
      state.dynamicRoutes = action.payload;
    },
  },
  extraReducers: {
    [layoutActions.getSubMenu.type]: state => {
      state.isFetching = true;
    },
    [layoutActions.getSubMenuSuccess.type]: (state, action) => {
      const {menuName, submenu} = action.payload.response;
      state.dynamicRoutes = {
        ...state.dynamicRoutes,
        [menuName]: submenu,
      };
      state.isFetching = false;
    },
    [layoutActions.getSubMenuFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [layoutActions.getLenderMenu.type]: state => {
      state.isFetching = true;
    },
    [layoutActions.getLenderMenuSuccess.type]: (state, action) => {
      state.sections = action.payload.response;
      state.isFetching = false;
    },
    [layoutActions.getLenderMenuFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [layoutActions.getFeatureFlags.type]: state => {
      state.isFetching = true;
      state.error = null;
    },
    [layoutActions.getFeatureFlagsSuccess.type]: (state, action) => {
      state.featureFlags = action.payload.response || [];
      state.isFetching = false;
    },
    [layoutActions.getFeatureFlagsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
  },
});

const {
  setIsFetching,
  setAllSubMenus,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;

export {
  setIsFetching,
  setAllSubMenus,
};
