import {goldApiService} from "app/infra/plugins/axios";
import {
  ConfigApiResources,
  ConfigApiInterface,
  GetGlobalFeatureFlagListPayload,
  CrIdPayload,
  GetGlobalConstantsListpayload,
  UpdateGlobalFlagPayload,
  UpdateCrFeatureFlagPayload,
  UpdateGlobalConstantPayload,
} from "./configUtils.types";

const ConfigUtilsApiEndpoints: ConfigApiResources = {
  GET_MODULE_LIST: {
    URL: "/dashboard/config/module-feature/module-list",
    METHOD: "GET",
  },
  GET_GLOBAL_FEATURE_FLAGS_LIST: {
    URL: "dashboard/config/module-feature",
    METHOD: "GET",
  },
  GET_CR_FEATURE_FLAGS_LIST: {
    URL: "/business/cr-feature-flag/cr/:crId/key-value-map",
    METHOD: "GET",
  },

  GET_GLOBAL_CONSTANTS_LIST: {
    URL: "/global-config/config-list",
    METHOD: "GET",
  },
  UPDATE_GLOBAL_FLAG: {
    URL: "/dashboard/config/module-feature",
    METHOD: "POST",
  },
  UPDATE_CR_FEATURE_FLAG: {
    URL: "/business/cr-feature-flag/cr/:crId/update-key-value",
    METHOD: "PUT",
  },

  UPDATE_GLOBAL_CONSTANT: {
    URL: "/global-config/config",
    METHOD: "POST",
  },
};

const ConfigApi: ConfigApiInterface = {
  getModuleList: async () => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.GET_MODULE_LIST,
    });
  },
  getGlobalFeatureFlagsList: async ({
    moduleEnum,
    pageNo,
    pageSize,
  }: GetGlobalFeatureFlagListPayload) => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.GET_GLOBAL_FEATURE_FLAGS_LIST,
      options: {
        queryParams: {
          moduleEnum,
          pageNo,
          pageSize,
        },
      },
    });
  },
  getCrFeatureFlagsList: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.GET_CR_FEATURE_FLAGS_LIST,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getGlobalConstantsList: async ({pageNo, pageSize}: GetGlobalConstantsListpayload) => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.GET_GLOBAL_CONSTANTS_LIST,
      options: {
        queryParams: {
          pageNo,
          pageSize,
        },
      },
    });
  },
  updateGlobalFlag: async ({
    moduleEnum,
    featureNameEnum,
    isFeatureActive,
  }: UpdateGlobalFlagPayload) => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.UPDATE_GLOBAL_FLAG,
      options: {
        queryParams: {
          moduleEnum,
          featureNameEnum,
          isFeatureActive,
        },
      },
    });
  },
  updateCrFeatureFlag: async ({flagName, value, crId, remark}: UpdateCrFeatureFlagPayload) => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.UPDATE_CR_FEATURE_FLAG,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          flagName,
          value,
          remark,
        },
      },
    });
  },

  updateGlobalConstant: async ({key, value, dataType}: UpdateGlobalConstantPayload) => {
    return await goldApiService({
      resource: ConfigUtilsApiEndpoints.UPDATE_GLOBAL_CONSTANT,
      options: {
        data: {
          key,
          value,
          dataType,
        },
      },
    });
  },
};

export {ConfigUtilsApiEndpoints};
export default ConfigApi;
