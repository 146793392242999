/* eslint-disable no-console */
import {PayloadAction} from "@reduxjs/toolkit";
import {call, delay, getContext, put, take, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {
  RequestIdPayload,
  SaveCustomerReqDetailsPayload,
  ServiceDeskResponse,
  IDsPayload,
  LoanIdPayload,
  UpdateShareStatusPayload,
  SaveCMOfferDetailsPayload,
  UpdateOrnamentPayload,
  GetVersionHistoryPayload,
  GetLoanRenewalClosureDetailPayload,
  CloseLoanRenewalPayload,
  LoanTypePayload,
  UpdateRoiPayload,
  UpdateCMAdjustmentRenewalDetailsPayload,
  AgriOptionPayload,
  GetOfferListPayload,
  CheckOfferEnginePayload,
} from "app/infra/services/api/serviceDesk/renewRebook/types";
import {snackBarActions} from "app/store/snackbar";
import renewRebookActions, {renewRebookActionTypes} from "./actions";
import servicesActions from "../actions";
import {offerEngineApplicabilityReceived, offerListReceived, resetOfferEngine, setForceSubmitCmForm, setLoanRenewalCloseStatus, setRenewRebookError, setRenewRebookLoading, setRenewRebookSuccess} from "./reducer";
import {Channel} from "redux-saga";
import {IGDialogActions} from "app/store/igDialog";
import {countdown} from "app/store/utils.saga";

const {
  saveCustomerReqDetailsSuccess,
  saveCustomerReqDetailsFailure,
  getOurOfferSuccess,
  getOurOfferFailure,
  getVersionHistorySuccess,
  getVersionHistoryFailure,
  updateShareStatusSuccess,
  updateShareStatusFailure,
  getNetPayableSuccess,
  getNetPayableFailure,
  updateOrnamentsDetailsSuccess,
  updateOrnamentsDetailsFailure,
  getOrnamentsDetailsSuccess,
  getOrnamentsDetailsFailure,
  getCustomerFinancialsSuccess,
  getCustomerFinancialsFailure,
  getCustomerRequirementsSuccess,
  getCustomerRequirementsFailure,
  getRepaymentHistorySuccess,
  getRepaymentHistoryFailure,
  saveCMOfferDetailsFailure,
  getCMOfferDetailsSuccess,
  getCMOfferDetailsFailure,
  getRenewRebookConfigSuccess,
  getRenewRebookConfigFailure,
  getCMConfigSuccess,
  getCMConfigFailure,
  getPaymentDetailsSuccess,
  getPaymentDetailsFailure,
  getLoanRenewalClosureDetailsSuccess,
  getLoanRenewalClosureDetailsFailure,
  closeLoanRenewalSuccess,
  closeLoanRenewalFailure,
  getLoanRenewalMappedPaymentsFailure,
  getLoanRenewalMappedPaymentsSuccess,
  getLoanRenewalStatusSuccess,
  getLoanRenewalStatusFailure,
  updateRoiSuccess,
  updateRoiFailure,
  getUserAcceptedOfferSuccess,
  getUserAcceptedOfferFailure,
  getGoldRateSuccess,
  getGoldRateFailure,
  getCMAdjustmentRenewalDetailsFailure,
  getCMAdjustmentRenewalDetailsSuccess,
  updateCMAdjustmentRenewalDetailsFailure,
  updateCMAdjustmentRenewalDetailsSuccess,
  getUserAcceptedOffer,
} = renewRebookActions;

function* generalErrorFlow(action: PayloadAction<ServiceDeskResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* saveCustomerReqDetailsFlow(
  action: PayloadAction<SaveCustomerReqDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.saveCustomerReqDetails,
    action.payload,
  );

  if (error) {
    yield put(saveCustomerReqDetailsFailure({response: null, error}));
  } else {
    yield put(saveCustomerReqDetailsSuccess({response, error: null}));
    yield put(
      servicesActions.getServiceRequestData({
        requestId: action.payload.requestId,
      }),
    );
    yield put(
      renewRebookActions.getCustomerRequirements({
        requestId: action.payload.requestId,
      }),
    );
    if (
      !action.payload.isCustomerRequirementAvailable ||
      (action.payload.isCustomerRequirementAvailable &&
        JSON.parse(String(action.payload.formData.get("isBetterOfferNeeded"))))
    ) {
      yield put(
        renewRebookActions.updateShareStatus({
          requestId: action.payload.requestId,
          shareWith: "CM",
        }),
      );
    }
  }
}

function* getOurOfferFlow(action: PayloadAction<IDsPayload>) {
  const api: GoldApi = yield getContext("api");

  const chan: Channel<number> = yield call(countdown, {
    seconds: 61,
    intervalTime: 1000,
  });

  try {
    while (true) {
      const seconds: number = yield take(chan);
      console.log("Seconds ---> ", seconds);
      if (seconds % 4 === 0) {
        console.log("Fetching Offer Expiry Status");
        const {response, error} = yield call(
          api.renewRebook.getOfferExpiryStatus,
          {
            requestId: action.payload.requestId,
            offerBy: "DEFAULT_OFFER",
          },
        );

        if (error) {
          yield put(
            snackBarActions.open({message: error.message, variant: "error"}),
          );
        }

        if (response === false) {
          localStorage.setItem("IS_OFFER_EXPIRED", "false");
          const {ourOffer, error} = yield call(
            api.renewRebook.getOurOffer,
            action.payload,
          );

          if (error) {
            yield put(getOurOfferFailure({ourOffer: null, error}));
          } else {
            yield put(getOurOfferSuccess({ourOffer, error: null}));
            yield put(
              servicesActions.getServiceRequestData({
                requestId: action.payload.requestId,
              }),
            );
            yield put(
              renewRebookActions.getNetPayable({
                requestId: action.payload.requestId,
                loanId: action.payload.loanId,
              }),
            );
          }
          chan.close();
        }
        else if(response === true) {
          localStorage.setItem("IS_OFFER_EXPIRED", "true");
        }
      }
    }
  } finally {
    if (localStorage.getItem("IS_OFFER_EXPIRED") === "true") {
      yield put(getOurOfferFailure({ourOffer: null, error: {
        message: "Offer has been expired. Please reload the window.",
        errors: [],
        code: "408",
        developerCode: "408",
        fieldError: "",
      }}));
      yield put(
        snackBarActions.open({
          message: "Offer has been expired. Please reload the window.",
          variant: "warning",
        }),
      );
    }
    localStorage.removeItem("IS_OFFER_EXPIRED");
    console.log("countdown terminated");
  }
}

function* getVersionHistoryFlow(
  action: PayloadAction<GetVersionHistoryPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {versionHistory, error} = yield call(
    api.renewRebook.getVersionHistory,
    action.payload,
  );

  if (error) {
    yield put(getVersionHistoryFailure({versionHistory, error}));
  } else {
    yield put(getVersionHistorySuccess({versionHistory, error}));
  }
}

function* updateShareStatusFlow(
  action: PayloadAction<UpdateShareStatusPayload>,
) {
  yield put(
    IGDialogActions.open({
      type: "loading",
      content: "Please Wait! Offer Calculation in progress.",
      showActions: false,
      timer: true,
      persist: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.updateShareStatus,
    action.payload,
  );

  if (error) {
    yield put(updateShareStatusFailure({response, error}));
  } else {
    let isOfferCalculated;
    const chan: Channel<number> = yield call(countdown, {
      seconds: 30,
      intervalTime: 1000,
    });
    localStorage.setItem("OFFER_CALCULATION_STATUS", "PENDING");

    try {
      while (true) {
        isOfferCalculated =
          localStorage.getItem("OFFER_CALCULATION_STATUS") === "SUCCESS";
        console.log("Loop ---> Is offer calculated ?", isOfferCalculated);
        if (isOfferCalculated) {
          console.log("Success ---> Offer Calculated");
          chan.close();
        }

        const seconds: number = yield take(chan);
        console.log("Seconds ---> ", seconds);
        if (seconds % 3 === 0) {
          console.log("Fetching service data");
          yield put(
            servicesActions.getServiceRequestData({
              requestId: action.payload.requestId,
            }),
          );
        }
      }
    } finally {
      if (isOfferCalculated) {
        const shareWithDisplayValue = {
          CM: "Credit Manager",
          CUSTOMER_APP: "Customer",
          CST: "Customer Support",
        };

        yield put(updateShareStatusSuccess({response, error}));
        yield put(
          getUserAcceptedOffer({
            requestId: action.payload.requestId,
          }),
        );
        yield put(
          IGDialogActions.open({
            type: "success",
            content: `New offer shared with the ${
              shareWithDisplayValue[action.payload.shareWith]
            } successfully`,
            showActions: false,
          }),
        );
      } else {
        yield put(
          updateShareStatusFailure({
            response,
            error: {
              message: "Something went wrong ! Please reload the window.",
              errors: [],
              code: "408",
              developerCode: "408",
              fieldError: "",
            },
          }),
        );
      }
      localStorage.removeItem("OFFER_CALCULATION_STATUS");
      console.log("countdown terminated");
    }
  }
}

function* getNetPayableFlow(action: PayloadAction<IDsPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getNetPayable,
    action.payload,
  );

  if (error) {
    yield put(getNetPayableFailure({response, error}));
  } else {
    yield put(getNetPayableSuccess({response, error}));
  }
}

function* getOrnamentDetailsFlow(
  action: PayloadAction<IDsPayload & AgriOptionPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getOrnamentDetails,
    action.payload,
  );

  if (error) {
    yield put(getOrnamentsDetailsFailure({response, error}));
  } else {
    yield put(getOrnamentsDetailsSuccess({response, error}));
  }
}

function* updateOrnamentDetailsFlow(
  action: PayloadAction<UpdateOrnamentPayload & AgriOptionPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.updateOrnamentDetails,
    action.payload,
  );

  if (error) {
    yield put(updateOrnamentsDetailsFailure({response, error}));
  } else {
    yield put(
      updateOrnamentsDetailsSuccess({
        response: {
          ...response,
          ...action.payload,
        },
        error,
      }),
    );
    yield put(
      renewRebookActions.getCMOfferDetails({
        requestId: action.payload.requestId,
      }),
    );
    yield put(
      renewRebookActions.getOrnamentsDetails({
        requestId: action.payload.requestId,
        loanId: action.payload.loanId,
        isAgriOptionSelectedByCM: action.payload.isAgriOptionSelectedByCM,
      }),
    );
  }
}

function* getCustomerFinancialsFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getCustomerFinancials,
    action.payload,
  );

  if (error) {
    yield put(getCustomerFinancialsFailure({response, error}));
  } else {
    yield put(getCustomerFinancialsSuccess({response, error}));
  }
}

function* getCustomerRequirementsFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getCustomerRequirements,
    action.payload,
  );

  if (error) {
    yield put(getCustomerRequirementsFailure({response, error}));
  } else {
    yield put(getCustomerRequirementsSuccess({response, error}));
  }
}

function* getRepaymentHistoryFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {repaymentHistory, error} = yield call(
    api.renewRebook.getRepaymentHistory,
    action.payload,
  );

  if (error) {
    yield put(getRepaymentHistoryFailure({repaymentHistory, error}));
  } else {
    yield put(getRepaymentHistorySuccess({repaymentHistory, error}));
  }
}

function* saveCMOfferDetailsFlow(
  action: PayloadAction<SaveCMOfferDetailsPayload & AgriOptionPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.saveCMOfferDetails,
    action.payload,
  );
  if (error) {
    yield put(saveCMOfferDetailsFailure({response, error}));
  } else {
    // yield put(saveCMOfferDetailsSuccess({response, error}));
    location.reload();
  }
}

function* getCMOfferDetailsFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");

  const chan: Channel<number> = yield call(countdown, {
    seconds: 61,
    intervalTime: 1000,
  });

  try {
    while (true) {
      const seconds: number = yield take(chan);
      console.log("Seconds ---> ", seconds);
      if (seconds % 4 === 0) {
        console.log("Fetching Offer Expiry Status");
        const {response, error} = yield call(
          api.renewRebook.getOfferExpiryStatus,
          {
            requestId: action.payload.requestId,
            offerBy: "CM",
          },
        );

        if (error) {
          yield put(
            snackBarActions.open({message: error.message, variant: "error"}),
          );
        }

        if (response === false) {
          localStorage.setItem("IS_OFFER_EXPIRED", "false");
          chan.close();
        }
      }
    }
  } finally {
    if (localStorage.getItem("IS_OFFER_EXPIRED") === "false") {
      const {response, error} = yield call(
        api.renewRebook.getCMOfferDetails,
        action.payload,
      );

      if (error) {
        yield put(getCMOfferDetailsFailure({response, error}));
      } else {
        yield put(getCMOfferDetailsSuccess({response, error}));
      }
    } else {
      yield put(getCMOfferDetailsFailure({response: null, error: {
        message: "Offer has been expired. Please reload the window.",
        errors: [],
        code: "408",
        developerCode: "408",
        fieldError: "",
      }}));
      yield put(
        snackBarActions.open({
          message: "Offer has been expired. Please reload the window.",
          variant: "warning",
        }),
      );
    }
    localStorage.removeItem("IS_OFFER_EXPIRED");
    console.log("countdown terminated");
  }
}

function* getServiceDeskFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.renewRebook.getRenewRebookConfig);
  if (error) {
    yield put(getRenewRebookConfigFailure({response, error}));
  } else {
    yield put(getRenewRebookConfigSuccess({response, error}));
  }
}

function* getCMConfigFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getCMConfig,
    action.payload,
  );
  if (error) {
    yield put(getCMConfigFailure({response, error}));
  } else {
    yield put(getCMConfigSuccess({response, error}));
  }
}

function* getPaymentDetails(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getPaymentDetails,
    action.payload,
  );
  if (error) {
    yield put(getPaymentDetailsFailure({response, error}));
  } else {
    yield put(getPaymentDetailsSuccess({response, error}));
  }
}

function* getLoanRenewalClosureDetailFlow(
  action: PayloadAction<GetLoanRenewalClosureDetailPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getLoanRenewalClosureDetail,
    action.payload,
  );
  if (error) {
    yield put(getLoanRenewalClosureDetailsFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(
      getLoanRenewalClosureDetailsSuccess({
        response,
        error: null,
        type: action.payload.loanType,
      }),
    );
    if (response.errorMessage) {
      yield put(
        snackBarActions.open({
          message: response.errorMessage,
          variant: "warning",
        }),
      );
    }
  }
}

function* closeLoanRenewalFlow(action: PayloadAction<CloseLoanRenewalPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.closeLoanRenewal,
    action.payload,
  );
  if (error) {
    yield put(closeLoanRenewalFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(closeLoanRenewalSuccess({response, error}));
    if (!action.payload.creatingGlDummyReceipt) {
      yield put(
        setLoanRenewalCloseStatus({
          type: action.payload.loanType === "GOLD_LOAN" ? "GL" : "PL",
          value: "INITIATED",
        }),
      );
    }
    yield put(snackBarActions.open({message: "Success", variant: "success"}));
  }
}

function* getLoanRenewalMappedPaymentsFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getLoanRenewalMappedPayments,
    action.payload,
  );
  if (error) {
    yield put(getLoanRenewalMappedPaymentsFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(getLoanRenewalMappedPaymentsSuccess({response, error}));
  }
}

function* updateRoiFlow(action: PayloadAction<UpdateRoiPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.updateRoi,
    action.payload,
  );
  if (error) {
    yield put(updateRoiFailure({response, error}));
  } else {
    yield put(updateRoiSuccess({response, error}));
    yield put(
      snackBarActions.open({
        message: "Successfully updated!!",
        variant: "success",
      }),
    );
    location.reload();
  }
}

function* getUserAcceptedOfferFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getUserAcceptedOffer,
    action.payload,
  );
  if (error) {
    yield put(getUserAcceptedOfferFailure({response: null, error}));
  } else {
    yield put(getUserAcceptedOfferSuccess({response, error: null}));
  }
}
function* getGoldRateFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getGoldRate,
    action.payload,
  );
  if (error) {
    yield put(getGoldRateFailure({response: null, error}));
  } else {
    yield put(getGoldRateSuccess({response, error: null}));
  }
}

function* getLoanRenewalStatusFlow(action: PayloadAction<LoanTypePayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.getLoanRenewalStatus,
    action.payload,
  );
  if (error) {
    yield put(getLoanRenewalStatusFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(
      getLoanRenewalStatusSuccess({
        response,
        error,
        type: action.payload.loanType === "GOLD_LOAN" ? "GL" : "PL",
      }),
    );
  }
}

function* getCMAdjustmentRenewalDetailsFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const chan: Channel<number> = yield call(countdown, {
    seconds: 61,
    intervalTime: 1000,
  });

  try {
    while (true) {
      const seconds: number = yield take(chan);
      console.log("Seconds ---> ", seconds);
      if (seconds % 4 === 0) {
        console.log("Fetching Offer Expiry Status");
        const {response, error} = yield call(
          api.renewRebook.getOfferExpiryStatus,
          {
            requestId: action.payload.requestId,
            offerBy: "CM",
          },
        );

        if (error) {
          yield put(
            snackBarActions.open({message: error.message, variant: "error"}),
          );
        }

        if (response === false) {
          localStorage.setItem("IS_OFFER_EXPIRED", "false");
          chan.close();
        }
      }
    }
  } finally {
    if (localStorage.getItem("IS_OFFER_EXPIRED") === "false") {
      const {response, error} = yield call(
        api.renewRebook.getCMAdjustmentRenewalDetails,
        action.payload,
      );

      if (error) {
        yield put(getCMAdjustmentRenewalDetailsFailure({response, error}));
        yield put(
          snackBarActions.open({message: error.message, variant: "error"}),
        );
      } else {
        yield put(getCMAdjustmentRenewalDetailsSuccess(response));
      }
    } else {
      yield put(getCMAdjustmentRenewalDetailsFailure({response: null, error: {
        message: "Offer has been expired. Please reload the window.",
        errors: [],
        code: "408",
        developerCode: "408",
        fieldError: "",
      }}));

      yield put(
        snackBarActions.open({
          message: "Offer has been expired. Please reload the window.",
          variant: "warning",
        }),
      );
    }
    console.log("countdown terminated");
    localStorage.removeItem("IS_OFFER_EXPIRED");
  }
}

function* updateCMAdjustmentRenewalDetailsFlow(
  action: PayloadAction<UpdateCMAdjustmentRenewalDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.updateCMAdjustmentRenewalDetails,
    action.payload,
  );
  if (error) {
    yield put(updateCMAdjustmentRenewalDetailsFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(updateCMAdjustmentRenewalDetailsSuccess(response));
    yield put(
      snackBarActions.open({
        message: "Successfully updated!!",
        variant: "success",
      }),
    );
    yield put(setForceSubmitCmForm(true));
  }
}

function* getOfferListFlow(action: PayloadAction<GetOfferListPayload>) {
  yield put(setRenewRebookLoading({action: "getOfferList"}));
  const POLLING_INTERVAL = 3000;
  const MAX_POLLING_TIME = 10000;
  const api: GoldApi = yield getContext("api");

  // Start polling logic
  const startTime = Date.now();

  while (true) {
    const {response, error} = yield call(
      api.renewRebook.getOfferList,
      action.payload,
    );
    if (error) {
      yield put(
        snackBarActions.open({
          message: error.message,
          variant: "error",
        }),
      );
      yield put(setRenewRebookError({action: "getOfferList", value: error.message || "Something went wrong"}));
      return;
    }

    if (response.status === "CREATED") {
      yield put(
        offerListReceived({
          fwcServiceRequestId: action.payload.fwcServiceRequest,
          offerResponseDtoList: response.offerResponseDtoList,
        }),
      );
      yield put(setRenewRebookSuccess({action: "getOfferList"}));
      return;
    }

    if (Date.now() - startTime >= MAX_POLLING_TIME || response.status === "FAILED") {
      const errorMsg = response.status === "FAILED" ? "Offer generation failed." : "Timeout: Offer creation took too long. Please try again";
      snackBarActions.open({
        message: errorMsg,
        variant: "error",
      }),
      yield put(setRenewRebookError({
        action: "getOfferList",
        value: errorMsg,
      }));
      yield put(setRenewRebookSuccess({action: "getOfferList"}));
      return;
    }
    yield delay(POLLING_INTERVAL);
  }
}

function* checkIfOfferEngineApplicableFlow(action: PayloadAction<CheckOfferEnginePayload>) {
  yield put(setRenewRebookLoading({action: "checkIfOfferEngineApplicable"}));
  yield put(resetOfferEngine());
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.renewRebook.checkIfOfferEngineApplicable,
    action.payload,
  );
  if (error) {
    yield put(setRenewRebookError({action: "checkIfOfferEngineApplicable", value: error.message || "Something went wrong"}));
    yield put(
      snackBarActions.open({
        message: error.message,
        variant: "error",
      }),
    );
  } else {
    yield put(offerEngineApplicabilityReceived({...response}));
    if(response.isOfferEngineApplicable && !response.isOfferSelected) {
      yield put(
        renewRebookActions.getOfferList({fwcServiceRequest: action.payload.fwcServiceRequest}),
      );
    }
  }
  yield put(setRenewRebookSuccess({action: "checkIfOfferEngineApplicable"}));
}

export default function* renewRebookSaga() {
  yield takeLatest(
    renewRebookActionTypes.SaveCustomerReqDetails,
    saveCustomerReqDetailsFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.SaveCustomerReqDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(renewRebookActionTypes.GetOurOffer, getOurOfferFlow);
  yield takeLatest(renewRebookActionTypes.GetOurOfferFailure, generalErrorFlow);

  yield takeLatest(
    renewRebookActionTypes.GetVersionHistory,
    getVersionHistoryFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.GetVersionHistoryFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.UpdateShareStatus,
    updateShareStatusFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.UpdateShareStatusFailure,
    generalErrorFlow,
  );

  yield takeLatest(renewRebookActionTypes.GetNetPayable, getNetPayableFlow);
  yield takeLatest(
    renewRebookActionTypes.GetNetPayableFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetOrnamentDetails,
    getOrnamentDetailsFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.GetOrnamentDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.UpdateOrnamentDetails,
    updateOrnamentDetailsFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.UpdateOrnamentDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetCustomerFinancials,
    getCustomerFinancialsFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetCustomerRequirements,
    getCustomerRequirementsFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetRepaymentHistory,
    getRepaymentHistoryFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.SaveCMOfferDetails,
    saveCMOfferDetailsFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.SaveCMOfferDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetCMOfferDetails,
    getCMOfferDetailsFlow,
  );
  yield takeLatest(
    renewRebookActionTypes.GetCMOfferDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetRenewRebookConfig,
    getServiceDeskFlow,
  );

  yield takeLatest(renewRebookActionTypes.GetCMConfig, getCMConfigFlow);

  yield takeLatest(renewRebookActionTypes.GetPaymentDetails, getPaymentDetails);

  yield takeLatest(
    renewRebookActionTypes.GetLoanRenewalClosureDetail,
    getLoanRenewalClosureDetailFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.CloseLoanRenewal,
    closeLoanRenewalFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetLoanRenewalStatus,
    getLoanRenewalStatusFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.GetLoanRenewalMappedPayments,
    getLoanRenewalMappedPaymentsFlow,
  );

  yield takeLatest(renewRebookActionTypes.UpdateRoi, updateRoiFlow);
  yield takeLatest(renewRebookActionTypes.UpdateRoiFailure, generalErrorFlow);

  yield takeLatest(
    renewRebookActionTypes.GetUserAcceptedOffer,
    getUserAcceptedOfferFlow,
  );

  yield takeLatest(renewRebookActionTypes.GetGoldRate, getGoldRateFlow);

  yield takeLatest(
    renewRebookActionTypes.GetCMAdjustmentRenewalDetails,
    getCMAdjustmentRenewalDetailsFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.UpdateCMAdjustmentRenewalDetails,
    updateCMAdjustmentRenewalDetailsFlow,
  );

  yield takeLatest(
    renewRebookActionTypes.CheckIfOfferEngineApplicable, checkIfOfferEngineApplicableFlow,
  );
  yield takeLatest(renewRebookActionTypes.GetOfferList, getOfferListFlow);
}
