import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  LoginPayload,
  ChangePasswordPayload,
} from "app/infra/services/api/auth/types";
import {GoogleLoginPayload} from "app/infra/services/api/auth/types";
import {
  takeLatest,
  put,
  getContext,
  call,
  takeLeading,
} from "redux-saga/effects";
import {snackBarActions} from "../snackbar";
import {actions, actionTypes, VerifyOtpPayload} from "./actions";
import {
  AuthState,
  resetAuthState,
  setAppSignature,
  setAuthToken,
} from "./reducer";
import {setAllSubMenus} from "../layout/reducer";

// >>>>>>>>> Auth Actions <<<<<<<<
const {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  register,
  renewAccessTokenFailure,
  changePasswordSuccess,
  changePasswordFailure,
  sendOtpSuccess,
  sendOtpFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
} = actions;

// >>>>>>>> Auth Flows <<<<<<<<<
// Login
function* loginFlow(action: PayloadAction<LoginPayload>) {
  // Access the api service of auth module
  // Directly accessible from getContext, no importing required
  const api: GoldApi = yield getContext("api");
  // Call the api service and wait for authState to be returned
  const authState: AuthState = yield call(api.auth.login, action.payload);

  // If there is an error, dispatch loginFailure
  if (authState.error) {
    yield put(loginFailure(authState));
  }

  // If there is no error, dispatch loginSuccess
  if (authState.authToken) {
    yield put(loginSuccess(authState));
  }
}

function* sendOtpFlow(action: PayloadAction<LoginPayload>) {
  const api: GoldApi = yield getContext("api");
  const authState: AuthState = yield call(api.auth.sendOtp, action.payload);

  if (authState.error) {
    yield put(sendOtpFailure(authState));
    yield put(
      snackBarActions.open({
        message: authState.error,
        variant: "error",
      }),
    );
  }

  if (authState.otpId) {
    yield put(sendOtpSuccess(authState));
    yield put(
      snackBarActions.open({
        message: "Otp Sent Successfully",
        variant: "success",
      }),
    );
  }
}

function* verifyOtpFlow(action: PayloadAction<VerifyOtpPayload>) {
  const api: GoldApi = yield getContext("api");
  const authState: AuthState = yield call(api.auth.verifyOtp, action.payload);

  if (authState.error) {
    yield put(verifyOtpFailure(authState));
    yield put(
      snackBarActions.open({
        message: authState.error,
        variant: "error",
      }),
    );
  }

  if (authState.authToken) {
    yield put(verifyOtpSuccess(authState));
  }
}

function* logoutFlow() {
  const api: GoldApi = yield getContext("api");
  const authState: AuthState = yield call(api.auth.logout);


  if (authState.error) {
    yield put(logoutFailure(authState));
  } else {
    yield put(logoutSuccess(authState));
    yield put(setAllSubMenus(null));
  }
}

function* renewAccessTokenFlow(action: PayloadAction<string>) {
  const api: GoldApi = yield getContext("api");
  const {authToken, error} = yield call(
    api.auth.refreshToken,
    action.payload,
  );

  if (authToken) {
    // Update Local Storage for backwards compatibility
    localStorage.setItem("authToken", authToken);

    // Dispatch refresh token success
    yield put(setAuthToken(authToken));
  } else if (error) {
    yield put(
      renewAccessTokenFailure({
        error,
      }),
    );
  }
}

function* changePasswordFlow(action: PayloadAction<ChangePasswordPayload>) {
  const api: GoldApi = yield getContext("api");
  const authState: AuthState = yield call(
    api.auth.changePassword,
    action.payload,
  );

  if (authState.error) {
    yield put(changePasswordFailure(authState));
  }

  if (authState.authToken) {
    yield put(changePasswordSuccess(authState));
  }

  yield put(resetAuthState());
}

function* postGoogleLoginFlow(action: PayloadAction<GoogleLoginPayload>) {
  const api: GoldApi = yield getContext("api");
  const authState: AuthState = yield call(
    api.auth.postGoogleLogin,
    action.payload,
  );

  if (authState.error) {
    yield put(loginFailure(authState));
  }

  if (authState.authToken) {
    yield put(loginSuccess(authState));
  }
}

function* loginFailureFlow(action: PayloadAction<AuthState>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error || "Login failed",
      variant: "error",
    }),
  );
}

// >>>>>>>> Auth Sagas <<<<<<<<<
export default function* authSaga() {
  yield takeLeading(actionTypes.Login, loginFlow);

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(register());
  });

  yield takeLeading(actionTypes.Logout, logoutFlow);

  yield takeLeading(actionTypes.RenewAccessToken, renewAccessTokenFlow);

  yield takeLeading(actionTypes.ChangePassword, changePasswordFlow);

  yield takeLeading(actionTypes.PostGoogleLogin, postGoogleLoginFlow);

  yield takeLeading(actionTypes.LoginSuccess, function* loginSuccessFlow() {
    yield put(snackBarActions.open({message: "Welcome", variant: "success"}));
  });

  yield takeLeading(actionTypes.LogoutSuccess, function* logoutSuccessFlow() {
    yield put(setAppSignature(null));
    yield put(
      snackBarActions.open({message: "Logged out", variant: "success"}),
    );
  });

  yield takeLeading(actionTypes.LoginFailure, loginFailureFlow);

  yield takeLeading(actionTypes.SendOtp, sendOtpFlow);

  yield takeLeading(actionTypes.VerifyOtp, verifyOtpFlow);
}
