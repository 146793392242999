import React, {useEffect, useState} from "react";
import {Grid, Tab, Tabs, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {IGLoading, IGModal} from "app/components";
import {goldApiService} from "app/infra/plugins/axios";
import {useSnackBar} from "app/store/hooks";
import {getValidTime} from "_metronic/utils/moment";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useParams} from "react-router-dom";

interface OtherChargesModalProps {
  open: boolean;
  setOpen: (value: boolean) => void
}

const OtherChargesModal: React.FC<OtherChargesModalProps> = ({
  open,
  setOpen,
}) => {
  const {showError} = useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("lms_charges");
  const [lmsChargesDetails, setLmsChargesDetails] = useState<null | any[]>();
  const [lenderChargesDetails, setLenderChargesDetails] = useState<null | any[]>();
  const {loanId} = useParams();

  const otherChargesTabList = [
    {
      label: "LMS Charges",
      value: "lms_charges",
    },
    {
      label: "Lender Charges",
      value: "lender_charges",
    },
  ];

  const lenderChargesCols: {
    [key: string]: string;
  } = {
    igDisplayName: "Charge Name",
    totalAmountDueTillDate: "Total Due Amount",
    outstandingAmount: "Outstanding Amount",
    paidAmount: "Paid Amount",
  };

  const fetchOtherCharges = async () => {
    setLoading(true);
    const {response, error} = await goldApiService({
      resource: {
        URL: selectedTab === "lms_charges" ? `/admin/business/loan/${Number(loanId)}/other-charge-breakup` : `/admin/business/v2/loan/${Number(loanId)}/lender-loan-charge-list`,
        METHOD: "GET",
      },
    });

    if (error) {
      showError(error.message || "Something went wrong");
      setOpen(false);
      setLoading(false);
      return;
    }
    if(selectedTab === "lms_charges") {
       setLmsChargesDetails(response);
    }
    else {
      setLenderChargesDetails(response);
    }
    setLoading(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if((selectedTab === "lms_charges" && !lmsChargesDetails) || (selectedTab === "lender_charges" && !lenderChargesDetails)) {
      fetchOtherCharges();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <IGModal
      open={open}
      onClose={() => setOpen(false)}
      title="View All Other Charges"
      width="sm"
    >
      <Box display={"flex"} gap={1} flexDirection={"column"} width={"100%"}>
      <Tabs value={selectedTab} onChange={handleChange}>
          {
            otherChargesTabList.map(charge => (
              <Tab label={charge?.label} value={charge?.value}/>
            ))
          }
        </Tabs>
      {loading ? (
        <IGLoading height="10vh" />
      ) : (
        <Grid
          container
          sx={{
            padding: selectedTab === "lms_charges" ? "1rem" : "",
            border: "1px solid #E5E5E5",
            borderRadius: "5px",
            overflowY: "auto",
          }}
        >
          {selectedTab === "lms_charges" ? lmsChargesDetails?.map((item) => (
            <>
              <Grid item xs={6} key={item.id}>
                <Typography fontWeight="bold">
                  {item.chargeDisplayName || item.igDisplayName}
                </Typography>
              </Grid>
              <Grid item xs={6} key={item.id}>
                <Typography alignItems="end">
                  {numberWithCurrencyAndComma(item.chargeDueAmount || item.chargeAmount)}
                  {item.chargeDueDate && `, applied on ${getValidTime(item.chargeDueDate).format("DD/MM/YYYY")} `}
                </Typography>
              </Grid>
            </>
          ))
          :
          (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{backgroundColor: " #e7e6e9"}}>
                  {Object.values(lenderChargesCols).map((col) => (
                    <TableCell key={col}>{col}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {lenderChargesDetails?.map((row, index) => (
                  <TableRow key={index}>
                    {Object.keys(lenderChargesCols).map((key) => (
                      <TableCell key={key}>{numberWithCurrencyAndComma(row[key])}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        </Grid>
      )}
      </Box>
    </IGModal>
  );
};

export default OtherChargesModal;
