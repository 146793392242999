import React, {useMemo} from "react";
import SubTitle from "../../styles/SubTitle";
import DocumentImageWrapper from "../styles/DocumentImageWrapper";
import DocIconWrapper from "../styles/DocIconWrapper";
import HeaderContainer from "./styles/HeaderContainer";
import BodyContainer from "./styles/BodyContainer";
import Title from "../../styles/Title";
import ColumnWrapper from "../styles/ColumnWrapper";
import DocumentWrapper from "../styles/DocumentWrapper";
import {InfoRounded, ZoomOutMap} from "@mui/icons-material";
import {Box} from "@mui/material";
import DocBlacklistedStatusChip from "../styles/DocBlackListedStatusChip";
import tippy from "tippy.js";
const PanCard = ({panDetails, setOpenPanModal}) => {
  const blackListedchipLabel = useMemo(() => {
    if (panDetails[0]?.isBlackListed) {
      return panDetails[0]?.blacklistReason === "IG_EMPLOYEE"
        ? "Blacklisted: IG employee"
        : "Blacklisted";
    }
  }, [panDetails]);

  const preVerifiedchipLabel = useMemo(() => {
    if (panDetails[0]?.provisionalDocStatus) {
      return panDetails[0]?.provisionalDocStatus;
    }
  }, [panDetails]);

  return (
    <DocumentWrapper>
      <HeaderContainer>
        {panDetails[0].documentType === "FORM60" ? (
          <Title>FORM 60</Title>
        ) : (
          <>
            <Title>PAN</Title>
            {panDetails && (
              <Box mb={1} mr={1} gap={1} display="flex" justifyContent="space-between">
                {blackListedchipLabel && (
                  <DocBlacklistedStatusChip
                    className={"errorChip"}
                    size="small"
                    label={blackListedchipLabel}
                    icon={<InfoRounded />}
                    onMouseEnter={event => {
                      tippy(event.currentTarget, {
                        content: panDetails[0]?.blacklistReason,
                        placement: "top",
                      });
                    }}
                    onMouseLeave={event => {
                      tippy(event.currentTarget).destroy();
                    }}
                  />
                )}

                {preVerifiedchipLabel && (
                  <DocBlacklistedStatusChip
                    className={"successChip"}
                    size="small"
                    label={preVerifiedchipLabel}
                  />
                )}
                {!blackListedchipLabel && !preVerifiedchipLabel && (
                  <DocBlacklistedStatusChip
                    className={"successChip"}
                    size="small"
                    label={"Approved"}
                  />
                )}
              </Box>
            )}
          </>
        )}
        <ZoomOutMap onClick={() => setOpenPanModal(true)} />
      </HeaderContainer>
      <BodyContainer>
        <ColumnWrapper>
          <SubTitle>Number</SubTitle>
          <Title>{panDetails[0].documentNumber || "Not Available"}</Title>
          <SubTitle>Remarks</SubTitle>
          <Title>{panDetails[0].remark}</Title>
        </ColumnWrapper>
        <ColumnWrapper>
          <SubTitle>Photo Verification</SubTitle>
          {/* aadhar photo */}
          <DocumentImageWrapper>
            {panDetails[0]?.fileUrl && (
              <DocIconWrapper src={panDetails[0].fileUrl} alt="pan_front" />
            )}
            {panDetails[0]?.backFileUrl && (
              <DocIconWrapper src={panDetails[0].backFileUrl} alt="pan_back" />
            )}
          </DocumentImageWrapper>
        </ColumnWrapper>
      </BodyContainer>
    </DocumentWrapper>
  );
};

export default PanCard;
