/* eslint-disable no-unused-vars */
export const SearchTypeEnum = {
  LOAN_ID: "loanId",
  REQUEST_ID: "requestId",
  CUSTOMER_MOBILE: "customerMobile",
  FRESHDESK_TICKET_ID: "freshdeskTicketId",
};

export type RequestType = "SERVICE" | "COMPLAINT";

export type ConfigProduct = "GOLD_LOAN" | "NON_GOLD_LOAN";

export const RequestTypeEnum = {
  SERVICE: "SERVICE",
  COMPLAINT: "COMPLAINT",
};

export const lenderEnum = {
  SHIVALIK: "SHIVALIK",
  PIRAMAL: "PIRAMAL",
  FINCARE: "FINCARE",
  LIQUILOANS: "LIQUILOANS",
};

export const ModifyRequestInputEnum = {
  CATEGORY: "CATEGORY",
  SUB_CATEGORY: "SUB_CATEGORY",
  CANCEL: "CANCEL",
  LOAN_ID: "LOAN_ID",
};

export const PendingAtEnum = {
  PENDING_AT_CM: "Credit Manager",
  PENDING_AT_CST: "Customer Support",
  PENDING_AT_CUSTOMER: "Customer",
  OFFER_ACCEPTED: "Offer Accepted",
};

export const ReschedulingLoanStatusEnum: {
  [key: string]:
    | "PENDING"
    | "PROCESSING"
    | "FAILED"
    | "SUCCESS"
    | "PARTIAL_STATE";
} = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  PARTIAL_STATE: "PARTIAL_STATE",
};

export type WaiveOffReason =
  | "REBOOK_RENEW_CASE"
  | "CUSTOMER_REQUEST"
  | "INTERNAL_DECISION"
  | "OTHER;";

export const WaiveOffReasonEnum = {
  CUSTOMER_COMPLAINT: "Customer Complaint",
  INTERNAL_DECISION: "Internal Decision",
  OTHER: "Other",
};

export const ClosureRequestTypeEnum = {
  EARLY_CLOSURE: "EARLY_CLOSURE",
  MATURITY_CLOSURE: "MATURITY_CLOSURE",
};

export enum ServiceRequestCategory {
  RENEW = "RENEW",
  REBOOK = "REBOOK",
  CLOSURE = "CLOSURE",
  PART_RELEASE = "PART_RELEASE",
  PART_PAYMENT = "PART_PAYMENT",
  RATE_CHANGE = "RATE_CHANGE",
  JUMPER_REMOVAL = "JUMPER_REMOVAL",
  RENEWAL = "RENEWAL",
}
export enum RateChangeReason {
  COMPETITOR_OFFERING_LOWER_ROI = "Competitor offering lower ROI",
  UNFAIR_RATE_HIKE = "Unfair Rate Hike by indiagold",
}

export enum ServiceRequestActionType {
  CLOSE_AND_RENEW = "CLOSE AND RENEW",
  UPDATE_RENEWAL_OFFER = "UPDATE RENEWAL OFFER",
  UPDATE_REBOOK_OFFER = "UPDATE REBOOK OFFER",
  MANAGE_RENEW_REQUEST = "MANAGE RENEW REQUEST",
  MANAGE_REBOOK_REQUEST = "MANAGE REBOOK REQUEST",
  MANAGE_RATE_CHANGE = "MANAGE RATE CHANGE",
  APPROVE_RATE_CHANGE = "APPROVE RATE CHANGE",
}

export enum PendingAtRateChangeEnum {
  CUSTOMER = "CUSTOMER",
  CREDIT_TEAM = "CREDIT_TEAM",
  PRODUCT_TECH = "PRODUCT_TECH",
  LENDING_PARTNER = "LENDING_PARTNER",
  RETENTION_TEAM = "RETENTION_TEAM",
  NA = "NA",
}

export enum AuctionStatusEnum {
  NO_NPA = "NO_NPA",
  TO_BE_AUCTIONED = "TO_BE_AUCTIONED",
  AUCTION_IN_PROGRESS = "AUCTION_IN_PROGRESS",
  AUCTIONED = "AUCTIONED",
}

export const LoanSchemeTypeEnum: Record<"REBATE_V2" | "FLAT" | "REBATE_V1" | "JUMPER", string> = {
  REBATE_V2: "Rebate",
  FLAT: "Flat",
  REBATE_V1: "Rebate",
  JUMPER: "Jumper",
};