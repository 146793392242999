import {goldApiService} from "app/infra/plugins/axios";
import {RequestType} from "app/models/services";
import {ApiResources} from "app/typings/api/goldApi.types";
import {ServiceDeskApiInterface} from "./serviceDesk.types";

const SERVICE_DESK_END_POINTS: ApiResources = {
  CREATE_NEW_REQUEST: {
    URL: "/admin/service-desk/request",
    METHOD: "POST",
  },
  GET_REQUEST_DETAILS: {
    URL: "/admin/service-desk/request/:requestId",
    METHOD: "GET",
  },
  UPDATE_REQUEST_DETAILS: {
    URL: "/admin/service-desk/request/:requestId",
    METHOD: "PUT",
  },
  LOANS_BY_MOBILE_NUMBER: {
    URL: "/admin/service-desk/loan/list",
    METHOD: "GET",
  },
  PAYMENT_LIST: {
    URL: "/admin/service-desk/loan/payment-list",
    METHOD: "GET",
  },
  LOAN_SERVICE_REQUEST_ID_LIST: {
    URL: "/admin/service-desk/loan/:loanId/service-request-id/list",
    METHOD: "GET",
  },
  UPDATE_UNCATEGORISED_PAYMENT: {
    URL: "/admin/service-desk/loan/payment/:userGoldLoanPaymentId",
    METHOD: "PUT",
  },
  SERVICE_REQUEST_LIST: {
    URL: "/admin/service-desk/request-list",
    METHOD: "GET",
  },
  CLOSURE_AMOUNT: {
    URL: "/admin/service-desk/loan/closure-amount",
    METHOD: "GET",
  },
  SERVICE_REQUEST_CONFIG_LIST: {
    URL: "/admin/service-desk/config/request-config/list",
    METHOD: "GET",
  },
  CANCEL_REQUEST: {
    URL: "/admin/service-desk/request/:requestId/cancel",
    METHOD: "POST",
  },
  MODIFY_REQUEST: {
    URL: "/admin/service-desk/request/:requestId/category",
    METHOD: "PUT",
  },
  CLOSE_LOAN: {
    URL: "/admin/service-desk/request/:requestId/loan/close",
    METHOD: "POST",
  },
  GET_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/closure/detail",
    METHOD: "GET",
  },
  ALLOW_CLOSURE_PAYMENT: {
    URL: "/admin/service-desk/request/:requestId/allow-closure-payment",
    METHOD: "POST",
  },
  GET_LOAN_DETAILS: {
    URL: "/admin/service-desk/loan/request/:requestId",
    METHOD: "GET",
  },
  GET_PAYMENT_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/payment",
    METHOD: "GET",
  },
  PUT_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/closure/detail",
    METHOD: "PUT",
  },
  PUT_LOAN_DETAIL: {
    URL: "/admin/service-desk/loan/request/:requestId",
    METHOD: "PUT",
  },
  VALIDATE_LOAN_CLOSE: {
    URL: "/admin/service-desk/request/:requestId/loan/close/validate",
    METHOD: "GET",
  },
  AGENT_LIST: {
    URL: "/admin/service-desk/request/agent/list",
    METHOD: "GET",
  },
  REASSIGN_AGENT: {
    URL: "/admin/service-desk/request/:requestId/reassign",
    METHOD: "PUT",
  },
  GET_REFUND_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/refund",
    METHOD: "GET",
  },
  UPDATE_REFUND_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/refund/update",
    METHOD: "PUT",
  },
  REFUND: {
    URL: "/admin/service-desk/request/:requestId/refund",
    METHOD: "POST",
  },
  RETRY_REFUND: {
    URL: "/admin/service-desk/request/:requestId/refund-retry",
    METHOD: "PUT",
  },
  GET_BANK_DETAILS: {
    URL: "/admin/payment/bank-detail",
    METHOD: "GET",
  },
  GET_UPI_DETAILS: {
    URL: "/admin/payment/upi-detail",
    METHOD: "GET",
  },
  ADD_BANK_DETAILS: {
    URL: "/admin/payment/bank-detail",
    METHOD: "POST",
  },
  ADD_UPI_DETAILS: {
    URL: "/admin/payment/upi-detail",
    METHOD: "POST",
  },
  VERIFY_ACCOUNT_DETAILS: {
    URL: "/admin/payment/account-detail/verify",
    METHOD: "POST",
  },
  GET_BANK_INFORMATION_FROM_IFSC_CODE: {
    URL: "/admin/payment/ifsc/:ifscCode",
    METHOD: "GET",
  },
  VERIFY_VPA: {
    URL: "/admin/payment/validate/vpa",
    METHOD: "POST",
  },
  GET_BYPASS_SCANNING_URL_LIST: {
    URL: "/admin/service-desk/request/:requestId/cm-approve-packet-scanning",
    METHOD: "GET",
  },
  POST_BYPASS_SCANNING_VERIFY: {
    URL: "/admin/service-desk/request/:requestId/cm-approve-packet-scanning",
    METHOD: "PUT",
  },
  DOWNLOAD_BYPASS_DOCUMENT: {
    URL: "/admin/service-desk/request/:requestId/download-document",
    METHOD: "GET",
  },
  POST_CUSTOMER_AVAILABILITY: {
    URL: "/admin/service-desk/request/:requestId/customer-availability",
    METHOD: "POST",
  },
  GET_CUSTOMER_AVAILABILITY: {
    URL: "/admin/service-desk/request/:requestId/customer-availability",
    METHOD: "GET",
  },
  SAVE_CM_OFFER_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/calculate-cm-offer",
    METHOD: "POST",
  },
  GET_CM_OFFER_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/cm-offer",
    METHOD: "GET",
  },
  GET_SERVICE_DESK_CONFIG: {
    URL: "/admin/service-desk/config",
    METHOD: "GET",
  },
  GET_CM_CONFIG: {
    URL: "/admin/service-desk/request/:requestId/cm-config",
    METHOD: "GET",
  },
  ALLOW_RENEWAL_PAYMENT: {
    URL: "/request/:requestId/allow-renewal-payment",
    METHOD: "POST",
  },
  GET_LOAN_RENEWAL_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/loan-renewal-closure-detail", // igClosureDate, loanType
    METHOD: "GET",
  },
  CLOSE_LOAN_RENEWAL: {
    URL: "/admin/service-desk/request/:requestId/close-loan-renewal",
    METHOD: "POST",
  },
  GET_LOAN_RENEWAL_MAPPED_PAYMENTS: {
    URL: "/admin/service-desk/request/:requestId/loan-renewal-payment",
    METHOD: "GET",
  },
  GET_LOAN_RENEWAL_STATUS: {
    URL: "/admin/service-desk/loan/request/:serviceRequestId/loan-detail",
    METHOD: "GET",
  },
  UPDATE_ROI: {
    URL: "/admin/service-desk/request/:requestId/update-cm-offer-roi",
    METHOD: "PUT",
  },
  GET_FORECLOSURE_CHARGE: {
    URL: "/admin/service-desk/loan/request/:requestId/foreclosure-charge",
    METHOD: "GET",
  },
  WAIVE_OFF_FORECLOSURE_CHARGE: {
    URL:
      "/admin/service-desk/loan/request/:requestId/foreclosure-charge/waiveOff",
    METHOD: "POST",
  },
  GET_ALL_LOANS: {
    URL: "/admin/business/user/:userId/loan-detail",
    METHOD: "GET",
  },
  GET_CANCELLATION_REASON_LIST: {
    URL: "/admin/service-desk/request/:requestId/cancellation-reason/list",
    METHOD: "GET",
  },
  GET_SOA_VERIFICATION: {
    URL: "/admin/service-desk/request/:requestId/soa-verification",
    METHOD: "GET",
  },
  UPDATE_SOA_VERIFICATION: {
    URL: "/admin/service-desk/request/:requestId/soa-verification",
    METHOD: "POST",
  },
  VALIDATE_RATE_CHANGE_REQUEST: {
    URL: "/admin/service-desk/rate-change/loan/:loanId/creation-allowed",
    METHOD: "GET",
  },
  GET_TAT_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/tat",
    METHOD: "GET",
  },
  GET_EVENT_HISTORY: {
    URL: "/admin/service-desk/request/:requestId/event-history",
    METHOD: "GET",
  },
  GET_EVENT_MASTER_LIST: {
    URL: "/admin/service-desk/config/manual-event-master/list",
    METHOD: "GET",
  },
  CREATE_EVENT: {
    URL: "/admin/service-desk/request/:requestId/create-manual-event",
    METHOD: "POST",
  },
  GET_LATEST_EVENT: {
    URL: "/admin/service-desk/request/:requestId/latest-event",
    METHOD: "GET",
  },
  GET_LENDER_CLOSURE_AMOUNT: {
    URL: "/admin/service-desk/loan/lan/:loanAccountNumber/lender-closure-amount",
    METHOD: "GET",
  },
  SEND_SURVEY_LINK: {
    URL: "/admin/service-desk/send-survey-link",
    METHOD: "POST",
  },
  VALIDATE_CIF_COMPROMISED: {
    URL: "/admin/loan/:loanId/validate-cif-compromised",
    METHOD: "GET",
  },
  INSTALLMENT_PAYMENT_LINK: {
    URL: "/admin/service-desk/:requestId/instalment-payment-link",
    METHOD: "POST",
  },
};

const serviceDeskApi: ServiceDeskApiInterface = {
  getServiceRequests: async (filters = {}) => {
    const queryParams = {
      ...filters,
    };
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.SERVICE_REQUEST_LIST,
      options: {queryParams},
    });

    if (error) {
      return {error, serviceRequests: []};
    }

    return {serviceRequests: response, error: null};
  },
  getServiceRequestById: async ({requestId}) => {
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_REQUEST_DETAILS,
      options: {pathVars: {requestId}},
    });

    if (error) {
      return {error, serviceRequest: null};
    }
    return {serviceRequest: response, error: null};
  },
  updateRequestDetails: async ({requestId, data}) => {
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.UPDATE_REQUEST_DETAILS,
      options: {data, pathVars: {requestId}},
    });
    if (error) {
      return {error, serviceRequest: null};
    }
    return {serviceRequest: response, error: null};
  },
  getServiceRequestConfigList: async ({product, requestType}) => {
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.SERVICE_REQUEST_CONFIG_LIST,
      options: {
        queryParams: {
          product: product,
          requestType: requestType,
        },
      },
    });
    if (error) {
      return {configList: [], error};
    }
    return {configList: response, error: null};
  },
  getAgentList: async () => {
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.AGENT_LIST,
    });
    if (error) {
      return {agentList: [], error};
    }
    return {agentList: response, error: null};
  },
  getLoansByMobileNumber: async (customerMobile) => {
    const queryParams = {customerMobile};
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.LOANS_BY_MOBILE_NUMBER,
      options: {queryParams},
    });
    if (error) {
      return {response, error};
    }
    return {response, error: null};
  },
  createNewRequest: async (payload) => {
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.CREATE_NEW_REQUEST,
      options: {
        data: payload.formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (error) {
      return {response: null, error};
    }
    return {response, error: null};
  },
  modifyRequest: async ({requestId, category, subCategory, loanId}) => {
    const pathVars = {requestId};
    const queryParams: {
      newLoanId?: number;
      serviceRequestTypeEnum: RequestType;
      serviceRequestCategoryEnum: string;
    } = {
      serviceRequestCategoryEnum: subCategory,
      serviceRequestTypeEnum: category,
    };
    if (loanId) {
      queryParams.newLoanId = loanId;
    }

    const response = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.MODIFY_REQUEST,
      options: {
        pathVars,
        queryParams,
      },
    });
    return response;
  },
  getLoanDetail: async ({requestId}) => {
    const pathVars = {requestId};
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_LOAN_DETAILS,
      options: {pathVars},
    });
  },
  reassignAgent: async ({requestId, agentId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.REASSIGN_AGENT,
      options: {
        pathVars: {
          requestId,
        },
        queryParams: {
          agentId,
        },
      },
    });
  },
  getCustomerAvailability: async ({requestId}) => {
    const {response, error} = await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_CUSTOMER_AVAILABILITY,
      options: {pathVars: {requestId}},
    });
    if (error) {
      return {customerAvailability: null, error};
    }
    return {customerAvailability: response, error: null};
  },
  confirmCustomerAvailability: async (payload) => {
    const {requestId, ...data} = payload;
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.POST_CUSTOMER_AVAILABILITY,
      options: {
        pathVars: {
          requestId,
        },
        data: {...data},
      },
    });
  },
  getBypassPacketList: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_BYPASS_SCANNING_URL_LIST,
      options: {pathVars: {requestId}},
    });
  },
  approveBypassPacketScanning: async ({requestId, status}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.POST_BYPASS_SCANNING_VERIFY,
      options: {pathVars: {requestId}, queryParams: {status}},
    });
  },
  getPaymentDetails: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_PAYMENT_DETAILS,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getForeclosureCharge: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_FORECLOSURE_CHARGE,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getAllLoans: async ({userId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_ALL_LOANS,
      options: {
        pathVars: {userId},
      },
    });
  },
  getCancellationReasonList: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_CANCELLATION_REASON_LIST,
      options: {
        pathVars: {requestId},
      },
    });
  },
  waiveOffForeclosureCharge: async ({formData, requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.WAIVE_OFF_FORECLOSURE_CHARGE,
      options: {
        data: formData,
        pathVars: {
          requestId,
        },
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getSoaVerification: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_SOA_VERIFICATION,
      options: {
        pathVars: {requestId},
      },
    });
  },
  updateSoaVerification: async ({requestId, formData}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.UPDATE_SOA_VERIFICATION,
      options: {
        data: formData,
        pathVars: {
          requestId,
        },
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  validateRateChangeRequest: async ({loanId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.VALIDATE_RATE_CHANGE_REQUEST,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getTatDetails: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_TAT_DETAILS,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getEventHistory: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_EVENT_HISTORY,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getEventMasterList: async ({
    product,
    requestCategory,
    requestStatus,
    requestType,
  }) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_EVENT_MASTER_LIST,
      options: {
        queryParams: {
          product,
          requestCategory,
          requestStatus,
          requestType,
        },
      },
    });
  },
  createEvent: async ({requestId, id, remarks}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.CREATE_EVENT,
      options: {
        pathVars: {requestId},
        data: {
          id,
          remarks,
        },
      },
    });
  },
  getLatestEvent: async ({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_LATEST_EVENT,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getLenderClosureAmount: async ({loanAccountNumber}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.GET_LENDER_CLOSURE_AMOUNT,
      options: {
        pathVars: {loanAccountNumber},
      },
    });
  },
  sendSurveyLink: async ({
    formType,
    loanId,
    requestId,
  }) => {
    const queryParams: Record<string, number | string> = {};
    if (loanId) {
      queryParams.loanId = loanId;
    }
    if (requestId) {
      queryParams.requestId = requestId;
    }
    if (formType) {
      queryParams.formType = formType;
    }
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.SEND_SURVEY_LINK,
      options: {
        queryParams,
      },
    });
  },
  validateCifCompromised: async ({loanId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.VALIDATE_CIF_COMPROMISED,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getInstallPaymentInfo: async({requestId}) => {
    return await goldApiService({
      resource: SERVICE_DESK_END_POINTS.INSTALLMENT_PAYMENT_LINK,
      options: {
        pathVars: {requestId},
      },
    });
  },
};

export {SERVICE_DESK_END_POINTS};

export default serviceDeskApi;
