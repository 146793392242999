import React from "react";
import {Box, Chip, Typography} from "@mui/material";
import {useAppSelector} from "app/store/hooks";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";
import GuarantorDocView from "../GuarantorDocView";
import {KycDocuments} from "app/models/loanRenewalRebook/personalDetails.model";
import {DocumentType, GUARANTOR_DOCUMENTS} from "../config";
import {InfoRounded} from "@mui/icons-material";
import tippy from "tippy.js";

const GuarantorDocumentItem = ({document}: { document: KycDocuments }) => {
  return (
    <Box display="flex" gap={2}>
      <Box>
        <Typography color="text.secondary" variant="body1">
          {GUARANTOR_DOCUMENTS[document.documentType as DocumentType].label}
        </Typography>
        <Typography>{document.documentNumber}</Typography>
        {document.provisionalDocStatus && (
          <Chip
            label={document.provisionalDocStatus || "Pre-Verified"}
            color="success"
            size="small"
            sx={{
              backgroundColor: "#c8e6c9",
              color: "#307e34",
              fontWeight: "bold",
            }}
          />
        )}
        {document?.isBlackListed && (
          <Chip
            label={"Blacklisted"}
            color="success"
            size="small"
            sx={{
              background: "#f2c1c1",
              color: "#c62828",
              fontWeight: "bold",
            }}
            icon={<InfoRounded />}
            onMouseEnter={event => {
              tippy(event.currentTarget, {
                content: document?.blacklistReason,
                placement: "top",
              });
            }}
            onMouseLeave={event => {
              tippy(event.currentTarget).destroy();
            }}
          />
        )}
      </Box>
      <Box>
        <GuarantorDocView guarantorDocument={document} />
      </Box>
    </Box>
  );
};

const GuarantorDocumentDetails = () => {
  const guarantorIdentifyDocs = useAppSelector(personalDetailsSelectors.getKycDocuments);

  const filteredDocs = guarantorIdentifyDocs.filter(doc =>
    ["GUARANTOR_AADHAAR", "GUARANTOR_PAN"].includes(doc.documentType),
  );

  if (!filteredDocs.length) {
    return null;
  }

  return (
    <Box
      bgcolor="#FFFFFF"
      border="1px solid #0000001F"
      borderRadius="4px"
      padding="16px 16px 16px 24px"
      display="flex"
      justifyContent="space-between"
    >
      {filteredDocs.map((doc, index) => (
        <GuarantorDocumentItem key={`${doc.documentType}-${index}`} document={doc} />
      ))}
    </Box>
  );
};

export default GuarantorDocumentDetails;
