import {createAction} from "@reduxjs/toolkit";
import {
  BypassScanningPayload,
  CloseLMJobPayload,
  CreateJobFromLeadPayload,
  GetLeadCreditDetailsPayload,
  GetLeadDetailPayload,
  GetLeadListPayload,
  GetLoanLockersPayload,
  GetLoanServicingJobListPayload,
  GetLocationFromAddressPayload,
  PickUpLocationPayload,
  UnscheduledResponse,
  UpdateLeadPayload,
  updateRmJobPayload,
  GetLeadCreditReportStatusPayload,
  GetLockerByLeadIdPayload,
  GetDeliverySlotConfigPayload,
  GetDeliverySlotChangeableDatePayload,
  UpdateDeliverySlotPayload,
  RemoveAgentPayload,
  ValidateCustomerDpdPayload,
} from "app/infra/services/api/scheduling/unscheduled/types";
import {UnscheduledState} from "./types";
import {LSJobAssignAgentInterface} from "app/models/lead";
import {
  GetNoteListPayload,
  UpdateNoteListPayload,
} from "app/infra/services/api/scheduling/openCR/types";
import {GoldApiError} from "app/typings/api/goldApi.types";

export const unscheduledActionTypes = {
  GetLoanServicingJobList: "[Unscheduled] Get Loan Servicing Job List",
  UpdateLSJobAssignAgentData: "[Unscheduled] Update assign agent form data",
  GetLoanServicingJobListSuccuss:
    "[Unscheduled] Get Loan Servicing Job List Success",
  GetLoanServicingJobListFailure:
    "[Unscheduled] Get Loan Servicing Job List Failure",
  GetLeadList: "[Unscheduled] Get Lead List",
  GetPickUpLocation: "[Unscheduled] Get pickup location start",
  GetPickUpLocationSuccess: "[Unscheduled] Get pickup location Success",
  GetPickUpLocationFailure: "[Unscheduled] Get pickup location Failure",
  GetLeadListSuccess: "[Unscheduled] Get Lead List Success",
  GetLeadListFailure: "[Unscheduled] Get Lead List Failure",
  GetLeadDetail: "[Unscheduled] Get Lead Detail",
  GetLeadDetailSuccess: "[Unscheduled] Get Lead Detail Success",
  GetLeadDetailFailure: "[Unscheduled] Get Lead Detail Failure",
  GetUnqualifiedReasons: "[Unscheduled] Get Unqualified Reasons",
  GetUnqualifiedReasonsSuccess: "[Unscheduled] Get Unqualified Reasons Success",
  GetUnqualifiedReasonsFailure: "[Unscheduled] Get Unqualified Reasons Failure",
  GetLoanLockers: "[Unscheduled] Get Loan Lockers",
  GetLoanLockersSuccess: "[Unscheduled] Get Loan Lockers Success",
  GetLoanLockersFailure: "[Unscheduled] Get Loan Lockers Failure",
  UpdateLeadDetail: "[Unscheduled] Update Lead Detail",
  UpdateLeadDetailSuccess: "[Unscheduled] Update Lead Detail Success",
  UpdateLeadDetailFailure: "[Unscheduled] Update Lead Detail Failure",
  CreateJobFromLead: "[Unscheduled] Create Job From Lead",
  CreateJobFromLeadSuccess: "[Unscheduled] Create Job From Lead Success",
  CreateJobFromLeadFailure: "[Unscheduled] Create Job From Lead Failure",
  CreateRMJob: "[Unscheduled] Create RM Job ",
  CreateRMJobSuccess: "[Unscheduled] Create RM Job Success",
  CreateRMJobFailure: "[Unscheduled] Create RM Job Failure",
  GetSendBackReasonList: "[Unscheduled] Get Send Back Reason List",
  GetSendBackReasonListSuccess:
    "[Unscheduled] Get Send Back Reason List Success",
  GetSendBackReasonListFailure:
    "[Unscheduled] Get Send Back Reason List Failure",
  UpdateRmJob: "[Unscheduled] Update RM Job",
  UpdateRmJobSuccess: "[Unscheduled] Update RM Job Success",
  UpdateRmJobFailure: "[Unscheduled] Update RM Job Failure",
  DeleteRmJob: "[Scheduled] Delete RM Job",
  DeleteRmJobSuccess: "[Scheduled] Delete RM Job Success",
  DeleteRmJobFailure: "[Scheduled] Delete RM Job Failure",
  GetTimeSlotList: "[Unscheduled] Get TimeSlot List",
  GetTimeSlotListFailure: "[Unscheduled] Get TimeSlot List Failure",
  GetTimeSlotListSuccess: "[Unscheduled] Get TimeSlot List Success",
  GetNoteList: "[Unscheduled] Get Note List",
  GetNoteListSuccess: "[Unscheduled] Get Note List Success",
  GetNoteListFailure: "[Unscheduled] Get Note List Failure",
  UpdateNoteList: "[Unscheduled] Update Note List",
  UpdateNoteListSuccess: "[Unscheduled] Update Note List Success",
  UpdateNoteListFailure: "[Unscheduled] Update Note List Failure",
  getLocationFromAddress: "[Unscheduled] Get Location From Address",
  getLocationFromAddressSuccess:
    "[Unscheduled] Get Location From Address Success",
  getLocationFromAddressFailure:
    "[Unscheduled] Get Location From Address Failure",
  CloseLMJob: "[Unscheduled] Close LM Job",
  CloseLMJobSuccess: "[Unscheduled] Close LM Job Success",
  CloseLMJobFailure: "[Unscheduled] Close LM Job Failure",
  GetBypassPacketScanningList: "[Unscheduled] Get Bypass Packet Scanning List",
  GetBypassPacketScanningListSuccess:
    "[Unscheduled] Get Bypass Packet Scanning List Success",
  GetBypassPacketScanningListFailure:
    "[Unscheduled] Get Bypass Packet Scanning List Failure",
  ApproveBypassScanning: "[Unscheduled] Approve Bypass Scanning",
  ApproveBypassScanningSuccess: "[Unscheduled] Approve Bypass Scanning Success",
  ApproveBypassScanningFailure: "[Unscheduled] Approve Bypass Scanning Failure",
  GetLeadCreditDetails: "[Unscheduled] Get Lead Credit Details",
  GetLeadCreditDetailsSuccess: "[Unscheduled] Get Lead Credit Details Success",
  GetLeadCreditDetailsFailure: "[Unscheduled] Get Lead Credit Details Failure",
  GetLeadCreditReportStatus: "[Unscheduled] Get Lead Credit Report Status",
  GetLeadCreditReportStatusSuccess:
    "[Unscheduled] Get Lead Credit Report Status Success",
  GetLeadCreditReportStatusFailure:
    "[Unscheduled] Get Lead Credit Report Status Failure",
  GetLockerByLeadId: "[Unscheduled] Get Locker By Lead Id",
  GetLockerByLeadIdSuccess: "[Unscheduled] Get Locker By Lead Id Success",
  GetLockerByLeadIdFailure: "[Unscheduled] Get Locker By Lead Id Failure",
  GetGoldDeliverySlots: "[Unscheduled] Get Gold Delivery Slots",
  GetGoldDeliverySlotsSuccess: "[Unscheduled] Get Gold Delivery Slots Success",
  GetGoldDeliverySlotsFailure: "[Unscheduled] Get Gold Delivery Slots Failure",
  GetGoldDeliverySlotChangeableDate:
    "[Unscheduled] Get Gold Delivery Slot Changeable Date",
  GetGoldDeliverySlotChangeableDateSuccess:
    "[Unscheduled] Get Gold Delivery Slot Changeable Date Success",
  GetGoldDeliverySlotChangeableDateFailure:
    "[Unscheduled] Get Gold Delivery Slot Changeable Date Failure",
  UpdateGoldDeliverySlot: "[Unscheduled] Update Gold Delivery Slot",
  UpdateGoldDeliverySlotSuccess:
    "[Unscheduled] Update Gold Delivery Slot Success",
  UpdateGoldDeliverySlotFailure:
    "[Unscheduled] Update Gold Delivery Slot Failure",
  RemoveAgentJob: "[Unscheduled] Remove agent",
  RemoveAgentJobSuccess: "[Unscheduled] Remove agent success",
  RemoveAgentJobFailure: "[Unscheduled] Remove agent failure",
 ValidateCustomerDpd: "[Unscheduled] Validate Customer Dpd",
 ValidateCustomerDpdSuccess: "[Unscheduled] Validate Customer Dpd success",
 ValidateCustomerDpdFailure: "[Unscheduled] Validate Customer Dpd failure",
};

export const unscheduledActions = {
  getLoanServicingJobList: createAction<GetLoanServicingJobListPayload>(
    unscheduledActionTypes.GetLoanServicingJobList,
  ),
  updateLSJobAssignAgentData: createAction<LSJobAssignAgentInterface>(
    unscheduledActionTypes.UpdateLSJobAssignAgentData,
  ),
  getLoanServicingJobListSuccess: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLoanServicingJobListSuccuss,
  ),
  getLoanServicingJobListFailure: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLoanServicingJobListFailure,
  ),
  getLeadList: createAction<GetLeadListPayload>(
    unscheduledActionTypes.GetLeadList,
  ),
  getLeadListSuccess: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLeadListSuccess,
  ),
  getLeadListFailure: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLeadListFailure,
  ),
  getLeadDetail: createAction<GetLeadDetailPayload>(
    unscheduledActionTypes.GetLeadDetail,
  ),
  getLeadDetailSuccess: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLeadDetailSuccess,
  ),
  getLeadDetailFailure: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLeadDetailFailure,
  ),
  getUnqualifiedReasons: createAction(
    unscheduledActionTypes.GetUnqualifiedReasons,
  ),
  getUnqualifiedReasonsSuccess: createAction<{
    reasons: UnscheduledState[];
    error: string | null;
  }>(unscheduledActionTypes.GetUnqualifiedReasonsSuccess),
  getUnqualifiedReasonsFailure: createAction<{
    reasons: UnscheduledState[];
    error: string | null;
  }>(unscheduledActionTypes.GetUnqualifiedReasonsFailure),
  getLoanLockers: createAction<GetLoanLockersPayload>(
    unscheduledActionTypes.GetLoanLockers,
  ),
  getLoanLockersSuccess: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLoanLockersSuccess,
  ),
  getLoanLockersFailure: createAction<UnscheduledState>(
    unscheduledActionTypes.GetLoanLockersFailure,
  ),
  createRMJob: createAction<{
    crId: number;
    data: LSJobAssignAgentInterface;
  }>(unscheduledActionTypes.CreateRMJob),
  createRMJobSuccess: createAction(unscheduledActionTypes.CreateRMJobSuccess),
  createRMJobFailure: createAction<UnscheduledState>(
    unscheduledActionTypes.CreateRMJobFailure,
  ),
  updateLeadDetail: createAction<{
    leadId: number;
    type: string;
    payload: UpdateLeadPayload;
  }>(unscheduledActionTypes.UpdateLeadDetail),
  updateLeadDetailSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateLeadDetailSuccess,
  ),
  updateLeadDetailFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateLeadDetailFailure,
  ),
  createJobFromLead: createAction<CreateJobFromLeadPayload>(
    unscheduledActionTypes.CreateJobFromLead,
  ),
  createJobFromLeadSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.CreateJobFromLeadSuccess,
  ),
  createJobFromLeadFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.CreateJobFromLeadFailure,
  ),
  getSendBackReasonList: createAction(
    unscheduledActionTypes.GetSendBackReasonList,
  ),
  getSendBackReasonListSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetSendBackReasonListSuccess,
  ),
  getSendBackReasonListFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetSendBackReasonListFailure,
  ),
  getPickupLocation: createAction<{ crId: number }>(
    unscheduledActionTypes.GetPickUpLocation,
  ),
  getPickupLocationSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetPickUpLocationSuccess,
  ),
  getPickupLocationFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetPickUpLocationFailure,
  ),
  updateRmJob: createAction<updateRmJobPayload>(
    unscheduledActionTypes.UpdateRmJob,
  ),
  updateRmJobSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateRmJobSuccess,
  ),
  updateRmJobFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateRmJobFailure,
  ),
  deleteRmJob: createAction<PickUpLocationPayload>(
    unscheduledActionTypes.DeleteRmJob,
  ),
  deleteRmJobSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.DeleteRmJobSuccess,
  ),
  deleteRmJobFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.DeleteRmJobFailure,
  ),
  getTimeSlotList: createAction(unscheduledActionTypes.GetTimeSlotList),
  getTimeSlotListSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetTimeSlotListSuccess,
  ),
  getTimeSlotListFailure: createAction(
    unscheduledActionTypes.GetTimeSlotListFailure,
  ),
  getNoteList: createAction<GetNoteListPayload>(
    unscheduledActionTypes.GetNoteList,
  ),
  getNoteListSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetNoteListSuccess,
  ),
  getNoteListFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetNoteListFailure,
  ),
  updateNoteList: createAction<UpdateNoteListPayload>(
    unscheduledActionTypes.UpdateNoteList,
  ),
  updateNoteListSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateNoteListSuccess,
  ),
  updateNoteListFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateNoteListFailure,
  ),
  getLocationFromAddress: createAction<GetLocationFromAddressPayload>(
    unscheduledActionTypes.getLocationFromAddress,
  ),
  getLocationFromAddressSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.getLocationFromAddressSuccess,
  ),
  getLocationFromAddressFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.getLocationFromAddressFailure,
  ),
  closeLMJob: createAction<CloseLMJobPayload>(
    unscheduledActionTypes.CloseLMJob,
  ),
  closeLMJobSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.CloseLMJobSuccess,
  ),
  closeLMJobFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.CloseLMJobFailure,
  ),
  getBypassPacketScanningList: createAction<BypassScanningPayload>(
    unscheduledActionTypes.GetBypassPacketScanningList,
  ),
  getBypassPacketScanningListSuccess: createAction<any>(
    unscheduledActionTypes.GetBypassPacketScanningListSuccess,
  ),
  getBypassPacketScanningListFailure: createAction<{
    error: GoldApiError;
  }>(unscheduledActionTypes.GetBypassPacketScanningListFailure),
  approveBypassScanning: createAction<BypassScanningPayload>(
    unscheduledActionTypes.ApproveBypassScanning,
  ),
  approveBypassScanningSuccess: createAction<any>(
    unscheduledActionTypes.ApproveBypassScanningSuccess,
  ),
  approveBypassScanningFailure: createAction<{ error: GoldApiError }>(
    unscheduledActionTypes.ApproveBypassScanningFailure,
  ),
  getLeadCreditDetails: createAction<GetLeadCreditDetailsPayload>(
    unscheduledActionTypes.GetLeadCreditDetails,
  ),
  getLeadCreditDetailsSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetLeadCreditDetailsSuccess,
  ),
  getLeadCreditDetailsFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetLeadCreditDetailsFailure,
  ),
  getLeadCreditReportStatus: createAction<GetLeadCreditReportStatusPayload>(
    unscheduledActionTypes.GetLeadCreditReportStatus,
  ),
  getLeadCreditReportStatusSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetLeadCreditReportStatusSuccess,
  ),
  getLeadCreditReportStatusFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetLeadCreditReportStatusFailure,
  ),
  getLockerByLeadId: createAction<GetLockerByLeadIdPayload>(
    unscheduledActionTypes.GetLockerByLeadId,
  ),
  getLockerByLeadIdSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetLockerByLeadIdSuccess,
  ),
  getLockerByLeadIdFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetLockerByLeadIdFailure,
  ),
  getGoldDeliverySlots: createAction<GetDeliverySlotConfigPayload>(
    unscheduledActionTypes.GetGoldDeliverySlots,
  ),
  getGoldDeliverySlotsSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetGoldDeliverySlotsSuccess,
  ),
  getGoldDeliverySlotsFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetGoldDeliverySlotsFailure,
  ),
  getGoldDeliverySlotChangeableDate: createAction<
    GetDeliverySlotChangeableDatePayload
  >(unscheduledActionTypes.GetGoldDeliverySlotChangeableDate),
  getGoldDeliverySlotChangeableDateSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetGoldDeliverySlotChangeableDateSuccess,
  ),
  getGoldDeliverySlotChangeableDateFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.GetGoldDeliverySlotChangeableDateFailure,
  ),
  updateGoldDeliverySlot: createAction<UpdateDeliverySlotPayload>(
    unscheduledActionTypes.UpdateGoldDeliverySlot,
  ),
  updateGoldDeliverySlotSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateGoldDeliverySlotSuccess,
  ),
  updateGoldDeliverySlotFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.UpdateGoldDeliverySlotFailure,
  ),
  removeAgentJob: createAction<RemoveAgentPayload>(
    unscheduledActionTypes.RemoveAgentJob,
  ),
  removeAgentJobSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.RemoveAgentJobSuccess,
  ),
  removeAgentJobFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.RemoveAgentJobFailure,
  ),
  validateCustomerDpd: createAction<ValidateCustomerDpdPayload>(
    unscheduledActionTypes.ValidateCustomerDpd,
  ),
  validateCustomerDpdSuccess: createAction<UnscheduledResponse>(
    unscheduledActionTypes.ValidateCustomerDpdSuccess,
  ),
  validateCustomerDpdFailure: createAction<UnscheduledResponse>(
    unscheduledActionTypes.ValidateCustomerDpdFailure,
  ),
};
