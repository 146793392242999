import React, {useMemo} from "react";
import SubTitle from "../../styles/SubTitle";
import DocumentImageWrapper from "../styles/DocumentImageWrapper";
import DocIconWrapper from "../styles/DocIconWrapper";
import HeaderContainer from "./styles/HeaderContainer";
import {InfoRounded, ZoomOutMap} from "@mui/icons-material";
import BodyContainer from "./styles/BodyContainer";
import Title from "../../styles/Title";
import ColumnWrapper from "../styles/ColumnWrapper";
import DocumentWrapper from "../styles/DocumentWrapper";
import {Box} from "@mui/material";
import DocBlacklistedStatusChip from "../styles/DocBlackListedStatusChip";
import tippy from "tippy.js";

const Aadhar = ({aadharDetails, setOpenAadhaarModal, title = "AADHAR"}) => {
  const blackListedchipLabel = useMemo(() => {
    if (aadharDetails[0]?.isBlackListed) {
      return aadharDetails[0]?.blacklistReason === "IG_EMPLOYEE"
        ? "Blacklisted: IG employee"
        : "Blacklisted";
    }
  }, [aadharDetails]);

  const preVerifiedchipLabel = useMemo(() => {
    if (aadharDetails[0]?.provisionalDocStatus) {
      return aadharDetails[0]?.provisionalDocStatus;
    }
  }, [aadharDetails]);

  return (
    <DocumentWrapper>
      <HeaderContainer>
        <Title>{title}</Title>
        {aadharDetails && (
          <Box mb={1} mr={1} gap={1} display="flex" justifyContent="space-between">
            {blackListedchipLabel && (
              <DocBlacklistedStatusChip
                className={"errorChip"}
                size="small"
                icon={<InfoRounded />}
                onMouseEnter={event => {
                  tippy(event.currentTarget, {
                    content: aadharDetails[0]?.blacklistReason,
                    placement: "top",
                  });
                }}
                onMouseLeave={event => {
                  tippy(event.currentTarget).destroy();
                }}
                label={blackListedchipLabel}
              />
            )}

            {preVerifiedchipLabel && (
              <DocBlacklistedStatusChip
                className={"successChip"}
                size="small"
                label={preVerifiedchipLabel}
              />
            )}
            {!blackListedchipLabel && !preVerifiedchipLabel && (
              <DocBlacklistedStatusChip className={"successChip"} size="small" label={"Approved"} />
            )}
          </Box>
        )}

        <ZoomOutMap onClick={() => setOpenAadhaarModal(true)} />
      </HeaderContainer>
      <BodyContainer>
        <ColumnWrapper>
          <SubTitle>Number</SubTitle>
          <Title>
            {(aadharDetails.length > 0 && aadharDetails[0].documentNumber) || "Not Available"}
          </Title>
          <SubTitle>Remarks</SubTitle>
          <Title>{(aadharDetails[0].length > 0 && aadharDetails[0].remark) || ""}</Title>
        </ColumnWrapper>
        <ColumnWrapper>
          <SubTitle>Photo Verification</SubTitle>
          {/* aadhar photo */}
          <DocumentImageWrapper>
            {aadharDetails.length > 0 && aadharDetails[0].fileUrl && (
              <DocIconWrapper src={aadharDetails[0].fileUrl} alt="aadhar_front" />
            )}
            {aadharDetails.length > 0 && aadharDetails[0].backFileUrl && (
              <DocIconWrapper src={aadharDetails[0].backFileUrl} alt="aadhar_back" />
            )}
          </DocumentImageWrapper>
        </ColumnWrapper>
      </BodyContainer>
    </DocumentWrapper>
  );
};

export default Aadhar;
