import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface SkipCifEnableProps {
  crId: number;
  remark: string;
  remarkEnum: string;
  lenderName: string;
  customerType: string;
}

export const skipCifEnable = async ({
  crId,
  remark,
  remarkEnum,
  lenderName,
  customerType,
}: SkipCifEnableProps) => {
  const API_ENDPOINTS: ApiResources = {
    SKIP_CIF_ENABLE: {
      URL: "admin/business/v2/cr/:crId/skip-cif-enable",
      METHOD: "PUT",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.SKIP_CIF_ENABLE,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        lenderName,
        remark,
        remarkEnum,
        customerType,
      },
    },
  });
};

export default skipCifEnable;
