import {RootState} from "app/store/store";

const activeLoansSelectors = {
  getCitiesList: (state: RootState) => {
    return Object.values(state.activeLoans.citiesById);
  },
  getCustomerRequests: (state: RootState) =>
    Object.values(state.activeLoans.customerRequestsById).filter(cr => cr.id),
  getLoanRenewalDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.customerRequestsById[crId]?.loanRenewalDetails,
  getLoanTopUpAmountByCrId: (state: RootState, crId: number) =>
    state.activeLoans.customerRequestsById[crId]?.loanTopUpAmountDetails,
  getSelectedCR: (state: RootState) => state.activeLoans.selectedCr,
  getErrors: (state: RootState) => state.activeLoans.errors,
  getLoading: (state: RootState) => state.activeLoans.loading,
  getFilters: (state: RootState) => state.activeLoans.filters,
  getOpenModals: (state: RootState) => state.activeLoans.openModals,
  getLMJobDetails: (state: RootState) => state.activeLoans.lMJobDetails,
  getLMUserList: (state: RootState) => state.activeLoans.lMUsers,
  getLoanMakerFieldsFlag: (state: RootState) => state.activeLoans.loanMakerInputFieldsFlag,
  getCmJobLatestStatus: (state: RootState) => state.activeLoans.cmJobLatestStatus,
  getLmInsuranceDetails: (state: RootState) => state.activeLoans.lmInsuranceDetails,

  getInsuranceFeatureFlag: (state: RootState) => state.activeLoans.isInsuranceUpsellRestricted,

  getOverrideLandDocumentDuplicateCheck: (state: RootState) =>
    state.activeLoans.overrideLandDocumentDuplicateCheck,

  getLoanMakerPrefilledDetails: (state: RootState) => state.activeLoans.loanMakerPrefilledDetails,

  getEmploymentDetailsEditFlag: (state: RootState) =>
    state.activeLoans.isEmploymentDetailsEditAllowed,
  getLandDocumentDetail: (state: RootState) => state.activeLoans.landDocumentDetail,

  getMatchingLandDocumentDetail: (state: RootState) => state.activeLoans.matchingLandDocumentDetail,

  getLandOwnerBorrowerList: (state: RootState) => state.activeLoans.landOwnerBorrowerList,

  getAgriTotalLoanAmount: (state: RootState) => state.activeLoans.totalAgriLoanAmountAllowed,

  getAgriFlowFlag: (state: RootState) => state.activeLoans.isAgriDocFlowAllowed,

  getLoanRequirementReason: (state: RootState) => state.activeLoans.loanRequirementReason,

  getPostLoanDocsFlag: (state: RootState) => state.activeLoans.postLoanDocsFlag,
  getLandStateList: (state: RootState) => state.activeLoans.landStateList,
  getPagination: (state: RootState) => state.activeLoans.pagination,
  getActiveTab: (state: RootState) => state.activeLoans.activeTab,
  getActiveLoansTabsList: (state: RootState) => state.activeLoans.activeLoansTabsList,
  getGuarantorDetailsRequired: (state: RootState) => state.activeLoans.isGuarantorDetailsRequired,
  getOrnamentCheckerFlag: (state: RootState) => state.activeLoans.isOrnamentsCheckerActive,
  getRejectCrDetail: (state: RootState) => state.activeLoans.rejectCrDetail,
  getRejectCrConfig: (state: RootState) => state.activeLoans.rejectCrConfig,
  getSendBackCrDetail: (state: RootState) => state.activeLoans.sendBackCrDetail,
  getSendBackCrConfig: (state: RootState) => state.activeLoans.sendBackCrConfig,
  getLenderApprovalDetails: (state: RootState) => state.activeLoans.lenderApprovalDetails,
  getBtScoreCardDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardDetails[crId],
  getBtScoreCardConfigBySelectedMilestone: (
    state: RootState,
    crId: number,
    selectedMilestone: string,
  ) =>
    state.activeLoans.btScoreCardConfg[crId]?.configRuleDtoList?.find(
      item => item?.ruleName === selectedMilestone,
    ),
  getSelectedBtScoreCardMilestone: (state: RootState) =>
    state.activeLoans.selectedBtScoreCardMilestone,
  getBtScoreDetailsByMilestone: (state: RootState, crId: number, selectedMilestone: string) =>
    state.activeLoans.btScoreCardDetails[crId]?.entityRatingList?.find(
      item => item?.ruleEnum === selectedMilestone,
    ),
  getBtScoreCardMilestoneCheckedByCm: (state: RootState) => state.activeLoans.mileStoneDisclaimer,

  getBtScoreCardCustomerDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardCustomerDetails[crId],
  getBtScoreCardConfig: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardConfg[crId],
  getLoanAmountSentToCustomerForBT: (state: RootState) =>
    state.activeLoans.isLoanAmountSentToCustomerForBT,
  getIsAllowCrAutoLoanRenewal: (state: RootState) => state.activeLoans.isAllowCrAutoLoanRenewal,
  getCreditReportStatus: (state: RootState) => state.activeLoans.creditReportStatus,
  getCreditDefaultHistoryByCrId: (state: RootState, crId: number) =>
    state.activeLoans.creditDefaultHistory[crId],
  getActionHistoryByCrId: (state: RootState, crId: number) => state.activeLoans.actionHistory[crId],
  getBtScoreCardEmploymentDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardEmploymentDetails[crId],
  getBtScoreCardDocumentDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardDocumentDetails[crId],
  getBtScoreCardBankingStatementDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardBankStatementDetails[crId],
  getBtScoreCardBankingAnalysisByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardBankingAnalysis[crId],
  getTransactionAnalysisByCrId: (state: RootState, crId: number) =>
    state.activeLoans.transactionAnalysis[crId],
  getFraudTransactionAnalysisByCrId: (state: RootState, crId: number) =>
    state.activeLoans.fraudTransactionAnalysis[crId],
};

export default activeLoansSelectors;
