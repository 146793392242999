import {call, getContext, put, takeLatest, takeLeading} from "redux-saga/effects";
import {loanActionTypes} from "./loan.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  GenerateVirtualAddressPayload,
  GetAllVirtualAddressesPayload,
  LoanIdPayload,
  LoanResponse,
  UserIdPayload,
} from "./loan.types";
import {GoldApi} from "../../infra/services/api";
import {loanActions} from "./index";
import {snackBarActions} from "../snackbar";
import {setLoanActionError, setLoanActionLoading, setLoanActionSuccess, setLoanError, setLoanLoading} from "./loan.reducer";

const {
  getLoanDetailsSuccess,
  getLoanDetailsFailure,
  getLoanRepaymentScheduleSuccess,
  getLoanRepaymentScheduleFailure,
  getAllUserLoansSuccess,
  getAllUserLoansFailure,
  getAllVirtualAddressesSuccess,
  getAllVirtualAddressesFailure,
  generateVirtualAddressSuccess,
  generateVirtualAddressFailure,
  getLenderLoanRepaymentScheduleFailure,
  getLenderLoanRepaymentScheduleSuccess,
  getLenderLoanDetailsFailure,
  getLenderLoanDetailsSuccess,
  getLoanDetails,
} = loanActions;

function* generalErrorFlow(action: PayloadAction<LoanResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getLoanDetailFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.getLoanDetails, action.payload);
  if (error) {
    yield put(getLoanDetailsFailure({response: null, error}));
  } else {
    yield put(getLoanDetailsSuccess({response, error: null}));
  }
}

function* getLenderLoanDetailFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.getLenderLoanDetails, action.payload);
  if (error) {
    yield put(getLenderLoanDetailsFailure({response: null, error}));
  } else {
    yield put(getLenderLoanDetailsSuccess({response, error: null}));
  }
}

function* getLoanRepaymentScheduleFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.getLoanRepaymentSchedule, action.payload);
  if (error) {
    yield put(getLoanRepaymentScheduleFailure({response: null, error}));
  } else {
    yield put(getLoanRepaymentScheduleSuccess({response, error: null}));
  }
}

function* getLenderLoanRepaymentScheduleFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.getLenderLoanRepaymentSchedule, action.payload);
  if (error) {
    yield put(getLenderLoanRepaymentScheduleFailure({response: null, error}));
  } else {
    yield put(getLenderLoanRepaymentScheduleSuccess({response, error: null}));
  }
}

function* getAllUserLoansFlow(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.getAllUserLoans, action.payload);
  if (error) {
    yield put(getAllUserLoansFailure({response: null, error}));
  } else {
    yield put(getAllUserLoansSuccess({response, error: null}));
  }
}

function* getAllVirtualAddressesFlow(action: PayloadAction<GetAllVirtualAddressesPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(api.loan.getAllVirtualAddress, action.payload);

  if (error) {
    yield put(getAllVirtualAddressesFailure({response: null, error}));
  } else {
    yield put(
      getAllVirtualAddressesSuccess({
        response: {
          loanId: action.payload.loanId,
          list: response,
        },
        error: null,
      }),
    );
  }
}

function* generateVirtualAddressFlow(action: PayloadAction<GenerateVirtualAddressPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.generateVirtualAddress, action.payload);
  if (error) {
    yield put(generateVirtualAddressFailure({response: null, error}));
    yield put(
      snackBarActions.open({
        message: error.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      generateVirtualAddressSuccess({
        response: {
          loanId: action.payload.loanId,
          list: response,
        },
        error: null,
      }),
    );
    yield put(
      snackBarActions.open({
        message: "Virtual address generated successfully",
        variant: "success",
      }),
    );
  }
}

function* markLenderValuationMismatchFlow(action: PayloadAction<LoanIdPayload>) {
  yield put(setLoanActionLoading({action: "markLenderValuationMismatch"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loan.markLenderValuationMismatch, action.payload);
  if (error) {
    yield put(setLoanActionError({action: "markLenderValuationMismatch"}));
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(getLoanDetails({loanId: action.payload.loanId}));
  }
  yield put(setLoanActionSuccess({action: "markLenderValuationMismatch"}));
}

export default function* loanSaga() {
  yield takeLeading(loanActionTypes.GetLoanDetails, getLoanDetailFlow);

  yield takeLatest(loanActionTypes.GetLoanRepaymentSchedule, getLoanRepaymentScheduleFlow);

  yield takeLatest(loanActionTypes.GetAllUserLoans, getAllUserLoansFlow);

  yield takeLatest(loanActionTypes.GetAllVirtualAddresses, getAllVirtualAddressesFlow);

  yield takeLatest(loanActionTypes.GenerateVirtualAddress, generateVirtualAddressFlow);

  yield takeLatest(
    loanActionTypes.GetLenderLoanRepaymentSchedule,
    getLenderLoanRepaymentScheduleFlow,
  );

  yield takeLatest(loanActionTypes.GetLenderLoanDetails, getLenderLoanDetailFlow);

  yield takeLatest(loanActionTypes.MarkLenderValuationMismatch, markLenderValuationMismatchFlow);
}
