import React from "react";
import {IGLoading} from "app/components";
import {allFeatures} from "app/models/feature";
import {authSelectors} from "app/store/auth";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {layoutActions, layoutSelector} from "app/store/layout";
import {useCallback, useEffect, useState} from "react";
import LayoutApi from "app/infra/services/api/layout/api";
import {setAllSubMenus} from "app/store/layout/reducer";

interface SubMenuData {
  menuName: string;
  sectionEnumList?: string[];
}

const RouteInitializer: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const user = useAppSelector(authSelectors.getUser);
  const dynamicRoutes = useAppSelector(layoutSelector.getDynamicRoutes);

  const [isRoutesLoading, setIsRoutesLoading] = useState(true);

  const fetchDynamicRoutes = useCallback(async () => {

    if (user?.products?.some((product) => product?.name === "LENDER_PORTAL")) {
      dispatch(layoutActions.getLenderMenu());
    }
    const subMenusToFetch: SubMenuData[] = [];

    Object.entries(allFeatures).forEach(([featureName, feature]) => {
      if (
        user?.userRoleList &&
        feature.dynamicSubMenu &&
        user.userRoleList.some((role) => feature.whitelistedRoles.includes(role))
      ) {
        if (featureName === "serviceDesk") {
          subMenusToFetch.push({
            menuName: "service-desk",
            sectionEnumList: ["CST_AGENT_CSAT"],
          });
        } else {
          subMenusToFetch.push({menuName: featureName});
        }
      }
    });

    try {
      const results = await Promise.allSettled(
        subMenusToFetch.map(async (menuData) => {

          const {response, error} = await (menuData?.menuName === "customerAnalytics"
          ? LayoutApi.getLenderDashboardList(menuData)
          : LayoutApi.getSubMenu(menuData));
          if(error) {
            // eslint-disable-next-line no-console
            console.error("Error From API:", menuData.menuName, error.message);
          }
          return response;
        }),
      );
      const successfulResults = results
        .filter((result): result is PromiseFulfilledResult<any> =>
          result.status === "fulfilled",
        )
        .map(result => result.value);
      const formattedRoutes: { [key: string]: any } = {};
      successfulResults.forEach(route => {
        formattedRoutes[route.menuName] = route.submenu;
      });
      dispatch(setAllSubMenus(formattedRoutes));
    } catch (error) {
      console.error("Error fetching submenus:", error);
      setIsRoutesLoading(false);
    } finally {
      setIsRoutesLoading(false);
    }
  }, [dispatch, JSON.stringify(user)]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsRoutesLoading(false);
      return;
    } else if (isAuthenticated && !dynamicRoutes) {
      fetchDynamicRoutes();
    } else if (dynamicRoutes) {
      setIsRoutesLoading(false);
    }
  }, [isAuthenticated, fetchDynamicRoutes, dynamicRoutes]);


  if (isRoutesLoading) {
    return <IGLoading />;
  }

  return <>{children}</>;
};

export default RouteInitializer;